//import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Animated } from "react-animated-css";
import { HashLink } from "react-router-hash-link";
import header_lions from "../../assets/images/header_lions.png";
import button_icon from "../../assets/images/button-icon.png";
import Vimeo from '@u-wave/react-vimeo';
export const Header = () => {
  const mintable = useSelector((state) => state.mint);

  return (
    <Animated
      animationIn="fadeInDown"
      animationOut="fadeOut"
      animationInDelay={0}
    >
      <header id="header">
        <div className="intro bg-block">
          <div className="section-body">
            <div className="gradient-overlay bg-block"></div>
            <div className="header-content text-center">
              <div>
                <Animated
                  animationIn="zoomIn"
                  animationOut="fadeOut"
                  animationInDelay={1000}
                >
                  {/* <img className="header_logo" src={logo_header} alt="logo_header"></img> */}
                  <h1 className="main-title">TO LEAD OR TO BE LED</h1>
                  <h2>
                    WE ARE GENERATION OF LEADERS. WE ARE A GENERATION OF LIONS.
                  </h2>
                  <h3 className="mt-3">Are you ready to lead?</h3>
                </Animated>
              </div>
              {/* <div className="mt-1">
                <Vimeo width="640" video="https://vimeo.com/709906434/c5b9c78d42" autoplay />
              </div> */}
              <Animated
                animationIn="flipInY"
                animationOut="fadeOut"
                animationInDelay={1500}
              >
                <HashLink className="btn btn-lg gradient-btn soldout" to="/getVerified#" smooth>
                  <img src={button_icon}></img>GET VERIFIED
                </HashLink>
              </Animated>
              {/* {
              !mintable.soldout && (
                <HashLink smooth to="/mint#root" className="btn btn-lg gradient-btn">MINT YOUR META BOUNTY HUNTERS</HashLink>
              )
            } */}
              {/* {
              mintable.soldout ? ( 
                <button className="btn btn-lg border-btn soldout">8,888 SOLD OUT</button>
              ) : (
                <button className="btn btn-lg border-btn">AVAILABLE : {(8888 - mintable.totalToken).toLocaleString()} OF 8,888</button>
              )
            } */}

              {/* </Animated> */}
            </div>
            <Animated
              animationIn="bounceInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDelay={1300}
            >
              <div>
                <img width="100%" src={header_lions} alt="header_lions"></img>
              </div>
            </Animated>
          </div>
        </div>
      </header>
    </Animated>
  );
};
