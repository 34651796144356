import {Animated} from "react-animated-css";

import header_lions from "../../assets/images/header_lions.png";
import button_icon from "../../assets/images/button-icon.png";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import "./verify.css";

export const Header = (props) => {
  const { flag, verifyPlusFlag } = props;
  const mintable = useSelector((state) => state.mint);

  return (
    <Animated
      animationIn="fadeInDown"
      animationOut="fadeOut"
      animationInDelay={0}
    >
      <header id="header">
        <div className="intro bg-block">
          <div className="section-body">
            <div className="gradient-overlay bg-block"></div>
            <div className="header-content text-center">
              <div>
                <Animated
                  animationIn="zoomIn"
                  animationOut="fadeOut"
                  animationInDelay={1000}
                >
                  {/* <img className="header_logo" src={logo_header} alt="logo_header"></img> */}
                  {flag == true ? (
                      <>
                        <Animated animationIn="FadeInUp" animationOut="fadeOut" animationInDelay={700}>
                          <h1 className="main-title">GET VERIFIED</h1>            
                        </Animated>
                        <Animated animationIn="FadeInUp" animationOut="fadeOut" animationInDelay={900}>
                          <h2 className="mt-3">NEW TO THE COMMUNITY? WHETHER YOU ARE WHITELISTING, MINTING, BOUGHT ON THE SECONDARY MARKET OR WERE TRANSFERRED YOUR NFT. FOLLOW THE SIMPLE STEPS BELOW TO GAIN ACCESS TO OUR COMMUNITY AND REWARDS.</h2>
                          <a href="#verify" className="btn btn-lg gradient-btn soldout">
                            <img src={button_icon}></img>GET VERIFIED
                          </a>
                        </Animated>
                      </>
                    ) : (
                      <Animated animationIn="FadeInUp" animationOut="fadeOut" animationInDelay={700}>
                        <h1 className="section-title">YOU ARE ALREADY REGISTERED</h1>
                        {verifyPlusFlag?(
                          <>
                            <h3>Please verify your email and phone number.</h3>
                            <HashLink smooth to="#getVerified" className="btn btn-lg gradient-btn soldout my-2"><img src={button_icon}></img>Get verified</HashLink>
                          </>
                        ):(
                          !mintable.soldout && (
                            <HashLink smooth to="/mint#" className="btn btn-lg gradient-btn soldout my-2"><img src={button_icon}></img>MINT YOUR NFT NOW</HashLink>
                          )
                        )}
                      </Animated>
                    )}
                </Animated>
              </div>
            </div>
            <Animated
              animationIn="bounceInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDelay={1300}
            >
              <div>
                <img width="100%" src={header_lions} alt="header_lions"></img>
              </div>
            </Animated>
          </div>
        </div>
      </header>
    </Animated>
  );
};
