import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { onUserSignUp, onReferrerIDVerify, onCheckUserAffiliate, onNewReferralIDVerify } from "../../redux/actions";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import { motion } from 'framer-motion/dist/framer-motion';
import authAxios from "../../redux/apis/axios";
import { validate } from "react-email-validator";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import discord_icon from '../../assets/images/verify/discord-icon.png';
import verify_bottom from '../../assets/images/verify/verify-bottom-b.png';

export const VerifyPlus = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const verify = useSelector((state) => state.auth);
  const affiliate = useSelector((state) => state.affiliate);
  //states
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    role: "",
    captcha: "",
    phone: ""
  });

  const [signupSucess, setSignupSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [disable, setDisable] = useState(true);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const [userPhoneLoading, setUserPhoneLoading] = useState(false);
  const [userEmailLoading, setUserEmailLoading] = useState(false);
  const [userPhoneCode, setUserPhoneCode] = useState("");
  const [userEmailCode, setUserEmailCode] = useState("");
  const [checkUserPhone, setCheckUserPhone] = useState(false);
  const [sentUserPhone, setSentUserPhone] = useState(false);
  const [checkUserEmail, setCheckUserEmail] = useState(false);
  const [sentUserEmail, setSentUserEmail] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (account && captchaToken != null && affiliate.referralID != "None" && checkUserPhone && userEmail != "") {
      setDisable(false);
    } else {
      setDisable(true);
    }
    stopLoading();
  }, [captchaToken, checkUserPhone, checkUserEmail, userEmail]);

  useEffect(() => {
    if(account) {
        dispatch(
            onCheckUserAffiliate({
                metamask_id: account
            })
        )
    }
  }, [account]);

  const stopLoading = () => {
    setSignUpLoading(false);
  };

  const verifyPhoneNumber = async () => {
    console.log(userPhone);
    if(userPhone == "") {
      swal("Warning!", "Please input your phone number!", "warning");
    } else {
      setUserPhoneLoading(true);
      if(!account) {
        swal("Warning!", "Please connect your metamask wallet!", "warning");
      } else {
        try{
          const res = await authAxios.post("/verifyPhone", {address: account, userPhone: userPhone});
          if(res.data.success == false) {
            swal("Warning!", res.data.message, "warning");
            if(res.data.error) console.log(res.data.error);
            setUserPhoneLoading(false);
          }else{
            setSentUserPhone(true);
          }
        } catch(err) {
          console.log("error!!!", err);
        }
      }
    }
  };
  const verifyPhoneCode = async () => {
    if(userPhoneCode == "") {
      swal("Warning!", "Please input your verificiation code!", "warning");
    } else {
      setUserPhoneLoading(true);
      try{
        const res = await authAxios.post("/verifyPhoneCode", {address: account, userPhoneCode: userPhoneCode});
        if(res.data.success == false) {
          swal("Warning!", res.data.message, "warning");
          setUserPhoneLoading(false);
        }else{
          setCheckUserPhone(true);
          setUserPhoneLoading(false);
        }
      } catch(err) {
        console.log("error!!!", err);
        setUserPhoneLoading(false);
      }
    }
  }
  const verifyEmail = async () => {
    if(userEmail == "") {
      swal("Warning!", "Please input your Email!", "warning");
    } else if(validate(userEmail) == false) {
      swal("Warning!", "Please input the correct Email!", "warning");
    } else {
      setUserEmailLoading(true);
      if(!account) {
        swal("Warning!", "Please connect your metamask wallet!", "warning");
      } else {
        try{
          const res = await authAxios.post("/verifyEmail", {address: account, userEmail: userEmail});
          if(res.data.success == false) {
            swal("Warning!", res.data.message, "warning");
            /* if(res.data.error) console.log(res.data.error); */
            setUserEmailLoading(false);
          }else{
            setSentUserEmail(true);
          }
        } catch(err) {
          console.log("error!!!", err);
          setUserEmailLoading(false);
        }
      }
    }
  };
  const verifyEmailCode = async ()=> {
    if(userEmailCode == "") {
      swal("Warning!", "Please input your email verification code!", "warning");
    } else {
      setUserEmailLoading(true);
      try{
        const res = await authAxios.post("/verifyEmailCode", {address: account, userEmailCode: userEmailCode});
        if(res.data.success == false) {
          swal("Warning!", res.data.message, "warning");
          setUserEmailLoading(false);
        }else{
          setCheckUserEmail(true);
          setUserEmailLoading(false);
        }
      } catch(err) {
        console.log("error!!!", err);
        setUserEmailLoading(false);
      }
    }
  }

  const signUp = async () => {
    console.log(affiliate);
    if (!disable) {
        setSignUpLoading(true);
        try{
            const res = await authAxios.post("/register2FA", {referrerId: affiliate.referrerID, referralId: affiliate.referralID, address: account, name: userName, phone: userPhone, email: userEmail});
            if(res.data.success == true) {
                swal("Good job!", "Your email and phone number are successfully registered!", "success");
                setSignupSuccess(true);
            } else {
                swal("Warning!", res.data.message, "warning");
            }
        } catch(err) {
            console.log("error!!!", err);
        }
        setSignUpLoading(false);
    }
  };


  return (
    <div id="verify">
      <div className="container mt-5">

        <div className="step-item" id="getVerified">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            viewport={{ once: true, amount: 0 }}
          >
            <div className="step-title">
              <h2>STEP 1</h2>
            </div>
            <div className="step-content" id="two_fa">
              <div>
                  <h3>EMAIL ADDRESS FOR NOTIFICATIONS OR NEWSLETTERS, ETC.</h3>
                  <p className="mt-2">Stay up to date with all the latest LNS News and Announcements</p>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-5 mt-5 align-items-end">
                  Your Name :
                  <input
                    type="text"
                    className="w-100 input-info"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="flex-1"></div>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-5 mt-5 align-items-end">
                  Your Email :
                  <input
                    type="email"
                    className="w-100 input-info"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                  ></input>
                  {/* {checkUserEmail == true ? (
                    <span className="tickIcon">&#10003;</span>
                    ) : (
                    <>
                      {!userEmailLoading ? (
                        <button className="btn btn-lg gradient-btn" onClick={verifyEmail}>Verify</button>
                      ) : (
                        !sentUserEmail ? (
                        <ReactLoading className="step-loading" type="spin" color="#c6872a" />
                        ) : (
                          <>
                          <input
                            className="code-input"
                            values={userEmailCode}
                            placeholder="Enter the code we sent"
                            onChange={(e) => {
                              setUserEmailCode(e.target.value);
                          }}/>
                          <button className="btn btn-sm gradient-btn mr-1" onClick={verifyEmailCode}>Enter</button>
                          <button className="btn btn-sm gradient-btn" onClick={()=> {setUserEmailLoading(false)}}>Cancel</button>
                          </>
                        )
                      )}
                    </>
                  )} */}
                </div>
                <div className="flex-1"></div>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-5 mt-5 align-items-end">
                  Your Phone:
                  <PhoneInput
                    type="tel"
                    className="w-100 input-info"
                    value={userPhone}
                    onChange={setUserPhone}
                  />
                  {checkUserPhone == true ? (
                    <span className="tickIcon">&#10003;</span>
                    ) : (
                    <>
                      {!userPhoneLoading ? (
                        <button className="btn btn-lg gradient-btn" onClick={verifyPhoneNumber}>Verify</button>
                      ) : (
                        !sentUserPhone ? (
                          <ReactLoading className="step-loading" type="spin" color="#c6872a" />
                        ) : (
                          <>
                            <input
                              className="code-input"
                              values={userPhoneCode}
                              placeholder="Enter the code we sent"
                              onChange={(e) => {
                                setUserPhoneCode(e.target.value);
                            }}/>
                            <button className="btn btn-sm gradient-btn mr-1" onClick={verifyPhoneCode}>Enter</button>
                            <button className="btn btn-sm gradient-btn" onClick={()=> {setUserPhoneLoading(false)}}>Cancel</button>
                          </>
                        )
                      )}
                    </>
                  )}
                </div>
                <div className="flex-1"></div>
              </div>
              <p className="mt-2" style={{fontSize:"small"}}>*By signing up via text, you agree to receive recurring automated marketing text messages at the cell phone used to sign up. Consent is not a condition of purchase. Msg and data rates may apply. View terms and conditions.</p>
            </div>
          </motion.div>
        </div>

        <div className="step-item row">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            viewport={{ once: true, amount: 0 }}
          >
            <div className="step-title">
              <h2>STEP 2</h2>
            </div>
            <div className="step-content">
              <div>
                <h3>GOOGLE RECAPTCHA VERIFICATION.</h3>
              </div>
              <div className="mt-2 d-flex">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITEKEY}
                  onChange={(token) => {
                    setCaptchaToken(token);
                    let copyErrors = { ...errors };
                    if (token) {
                      copyErrors.captcha = undefined;
                    } else {
                      copyErrors.captcha = "";
                    }
                    setErrors(copyErrors);
                  }}
                  onErrored={(e) => {
                    let copyErrors = { ...errors };
                    copyErrors.captcha = "";
                    setErrors(copyErrors);
                  }}
                />
              </div>
            </div>
          </motion.div>
        </div>

        <div className="sign_up text-center mt-5">
          {signupSucess == true ? (
            <span className="tickIcon">&#10003;</span>
          ) : (
            <>
              {!signUpLoading ? (
                <a
                  className="btn btn-verify gradient-btn"
                  onClick={signUp}
                  disabled={disable}
                >
                  Register
                </a>
              ) : (
                <div className="d-flex align-items-center justify-center text-center">
                  <ReactLoading type="spin" color="#c6872a" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <img className="bottom-bg" src={verify_bottom} alt="verify-bottom"/>
    </div>
  );
};
