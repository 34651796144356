import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navbar } from "../components/navbar";
import { MintSection } from "../components/mint/mintSection";
import { SubFooter } from "../components/sub_footer2";

import { onCheckMintable } from "../redux/actions";

const Mint = (props) => {
  const dispatch = useDispatch();
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const [preLoading, setPreLoading] = useState(false);
  const mintpageFlag = true;

  useEffect(() => {
    if (account) {
      setPreLoading(true);
      dispatch(
        onCheckMintable({
          address: account,
        })
      );
    }
  }, [account]);

  return (
    <div>
      <Navbar metamaskConnected={metamaskConnected} setMetamaskConnnected={setMetamaskConnnected} account={account} mintpageFlag={mintpageFlag}/>
      <MintSection setPreLoading={setPreLoading} preLoading={preLoading} account={account} metamaskConnected={metamaskConnected}/>
      <SubFooter page="mint" />
    </div>
  );
};

export default Mint;
