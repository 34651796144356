var Web3 = require("web3");

export const bidToAuction  = async (id, tokenIds, address) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        window.ethereum.enable();
    } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
    } else {
        window.alert(
            "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
    }
    var web3 = window.web3;

    const LNSAuction = require("./LNSAuction_test.json");
    const contractAddress = web3.utils.toChecksumAddress(process.env.REACT_APP_AUCTION_CONTRACT_ADDRESS);
    const contract = new web3.eth.Contract(LNSAuction.abi, contractAddress);
    
    let e;
    try {
        e = await contract.methods.bidToAuction(id, tokenIds).estimateGas({
            from: address
        });
    } catch (u) {
        return { success: false, type: 'bidToAuction: estimategas' }
    }

    let d = await web3.eth.getGasPrice();
    let c;
    
    try {
        c = await contract.methods.bidToAuction(id, tokenIds).send({
            from: address,
            gas: parseInt(e),
            gasPrice: parseInt(1.2 * d),
            maxFeePerGas: null,
        })
    } catch (u) {
        return {success: false, type:"bidToAuction"};
    }

    if(c){
        return {success: c.status, type:"bidToAuction"};
    }
}