import { Navbar } from "../components/navbar";
import { AuctionSection } from "../components/auction_sub/auctionSection";
import { Explain } from "../components/auction_sub/explain";
import { SubFooter } from "../components/sub_footer2";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const AuctionSub = (props) => {
    const { metamaskConnected, account, setMetamaskConnnected } = props;

    return (
        <div>
            <Navbar metamaskConnected={metamaskConnected} setMetamaskConnnected={setMetamaskConnnected} account={account} />
            <AuctionSection metamaskConnected={metamaskConnected} setMetamaskConnnected={setMetamaskConnnected} account={account}/>
            {/* <Explain /> */}
            <SubFooter />
        </div>
    );
};

export default AuctionSub;