import button_icon from "../../assets/images/button-icon.png";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

export const Ready = (props) => {
  const mintable = useSelector((state) => state.mint);
  return (
    <motion.div
      initial={{ opacity: 0, y: 200 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, type: "spring", stiffness: 100 }}
      viewport={{ once: true, amount: 0 }}
    >
      <div id="ready" className="ready text-center bg-block">
          <h1 className="ready-subtitle">ARE YOU READY TO LEAD?</h1>
          <h1 className="ready-title">ARE YOU READY TO ROAR?</h1>
          {/* <button className="btn btn-lg gradient-btn soldout my-2">
            <img src={button_icon}></img>MINT COMING SOON
          </button> */}
          {
            !mintable.soldout && (
              <HashLink smooth to="/mint#" className="btn btn-lg gradient-btn soldout my-2"><img src={button_icon}></img>MINT YOUR NFT NOW</HashLink>
            )
          }
      </div>
    </motion.div>
  );
};
