//import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Animated } from "react-animated-css";
import button_icon from "../../assets/images/button-icon.png";
import './vault.css';
export const Header = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected, mintpageFlag } = props;
  const mintable = useSelector((state) => state.mint);
  const handleConnectWallet = async () => {
    if (window.ethereum) {
      await window.ethereum.enable();
      setMetamaskConnnected(true);
      window.location.reload();
    } else {
      window.open("https://metamask.io/", "_blank");
    }
  };

  return (
    <Animated
      animationIn="fadeInDown"
      animationOut="fadeOut"
      animationInDelay={0}
    >
      <header id="header" className="vault">
        <div className="intro bg-block">
          <div className="section-body">
            <div className="gradient-overlay bg-block"></div>
            <div className="header-content text-center">
              <div>
                <Animated
                  animationIn="zoomIn"
                  animationOut="fadeOut"
                  animationInDelay={1000}
                >
                  {/* <img className="header_logo" src={logo_header} alt="logo_header"></img> */}
                  <h1 className="main-title">LNS VAULT</h1>
                  <h3>
                    Welcome Lion!<br/>Connect the wallet holding your Lions Not Sheep NFT to view your collection, reflections and team.
                  </h3>
                </Animated>
              </div>
              <Animated
                animationIn="flipInY"
                animationOut="fadeOut"
                animationInDelay={1500}
              >
                {metamaskConnected ? (
                  <>
                    <button className="btn btn-sm gradient-btn">
                      <img src={button_icon} />
                      {account && (
                        <>
                          {account.substring(0, 5)}...
                          {account.substring(account.length - 4)}
                        </>
                      )}
                    </button>
                  </>
                ) : (
                   <button className="btn btn-lg gradient-btn soldout" onClick={handleConnectWallet}>
                    <img src={button_icon}></img>CONNECT WALLET
                   </button>
                )}     
              </Animated>
            </div>
          </div>
        </div>
      </header>
    </Animated>
  );
};
