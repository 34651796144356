import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navbar } from "../components/navbar";
import { MintSection } from "../components/mint_cross/mintSection";
import { SubFooter } from "../components/sub_footer2";

import { onCheckMintable, onGetMintData } from "../redux/actions";

const MintCross = (props) => {
  const dispatch = useDispatch();
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const [preLoading, setPreLoading] = useState(false);
  const mintpageFlag = true;

  /* useEffect(() => {
      setPreLoading(true);
      dispatch(
        onCheckMintable({
          address: process.env.REACT_APP_CROSS_WALLET,
        })
      );
      dispatch(onGetMintData({ address: process.env.REACT_APP_CROSS_WALLET, count: 1 }));
  }, [account]); */

  return (
    <div>
      <Navbar metamaskConnected={metamaskConnected} setMetamaskConnnected={setMetamaskConnnected} account={account} mintpageFlag={mintpageFlag}/>
      <MintSection setPreLoading={setPreLoading} preLoading={preLoading} account={account} metamaskConnected={metamaskConnected}/>
      <SubFooter page="mint" />
    </div>
  );
};

export default MintCross;