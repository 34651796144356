import { motion } from "framer-motion/dist/framer-motion";
import Slider from "react-slick";

import sub_logo from "../../assets/images/sub-logo.png";
import freeman from "../../assets/images/freeman.png";

export const Why = (props) => {
  const sliderArray = [];

  const des_array = [
    {
      title: "Charitable DAO Givebacks",
      des: "The LNS Community will contribute to Do Good Causes voted on by the Community DAO (Decentralized Autonomous Organization).",
      img: "../img/image/why/why4-bg.png",
      img2: "../img/image/why/why4-item.png",
    },
    // {
    //   title: "RECEIVE REFLECTION REWARDS EVERY MONTH",
    //   des: "Not only are you doing good and giving back you are receiving rewards every month just for being a part of the community. All Lions Not Sheep NFT Holders will receive a monthly reflection for their commitment. Tracking and rewarding all NFT holders",
    //   content: "",
    //   img: "../img/image/why/why1-bg.png",
    //   img2: "../img/image/why/why1-item.png",
    // },
    {
      title: "Exclusive LNS Utility Auction",
      des: "A one of a kind decentralized application (dApp) built for Lions Not Sheep NFT holders. LNS holders will be able to auction their NFTs to upgrade and receive rewards such as global events, re",
      content: "",
      img: "../img/image/why/why2-bg.png",
      img2: "../img/image/why/why2-item.png",
    },
    // {
    //   title: "Affiliate Referral Rewards",
    //   des: "once you’ve become an LNS you’ll be able to earn additional rewards for referring new members to the community. The more you contribute the more rewards you can receive.",
    //   content: "",
    //   img: "../img/image/why/why3-bg.png",
    //   img2: "../img/image/why/why3-item.png",
    // },
    // {
    //   title: "Proven Utility with Affiliate and Direct Reflection Rewards",
    //   des: "LNS has a unique system that has successfully paid out thousands of community members in their other projects. ",
    //   content: "",
    //   img: "../img/image/why/why4-bg.png",
    //   img2: "../img/image/why/why4-item.png",
    // },
    {
      title: "Monthly Community Giveaways",
      des: "We will have monthly community giveaways available to all Lions Not Sheep NFT Holders.",
      content: "",
      img: "../img/image/why/why5-bg.png",
      img2: "../img/image/why/why5-item.png",
    },
    {
      title: "Charitable DAO Givebacks",
      des: "The LNS Community will contribute to Do Good Causes voted on by the Community DAO (Decentralized Autonomous Organization).",
      img: "../img/image/why/why4-bg.png",
      img2: "../img/image/why/why4-item.png",
    },
    {
      title: "Exclusive LNS Utility Auction",
      des: "A one of a kind decentralized application (dApp) built for Lions Not Sheep NFT holders. LNS holders will be able to auction their NFTs to upgrade and receive rewards such as global events, re",
      content: "",
      img: "../img/image/why/why2-bg.png",
      img2: "../img/image/why/why2-item.png",
    },
    {
      title: "Monthly Community Giveaways",
      des: "We will have monthly community giveaways available to all Lions Not Sheep NFT Holders.",
      content: "",
      img: "../img/image/why/why5-bg.png",
      img2: "../img/image/why/why5-item.png",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    autoplaySpeed: 1000,
    initialSlide: 2,
    swipeToSlide: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  for (let i = 1; i <= 6; i++) {
    sliderArray.push(
      <div className={"text-center slider-item item-" + i} key={i}>
        <div>
          <img className="" src={des_array[i - 1].img} />
          <img className="absolute-img img-responsive" src={des_array[i - 1].img2} />
        </div>
        <div className="slide-description">
          <h2 className="gradient-text">
            {des_array[i - 1].title}
          </h2>
          <p className="text-white">{des_array[i - 1].des}</p>
          <p>{des_array[i - 1].content}</p>
        </div>
      </div>
    );
  }
  return (
    <div id="why" className="purpose text-center">
      <div className="container-fluid purpose-body2 bg-block">
        <div className="clearfix"></div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          viewport={{ once: true, amount: 0 }}
        >
          <div className="purpose-middle mt-5">
            <div className="section-body" id="utilities">
              <img src={sub_logo} className="" alt="purpose_logo"></img>
              <h1 className="gradient-text section-subtitle">
                WHY SHOULD I JOIN
              </h1>

              <h1 className="main-title">LIONS NOT SHEEP</h1>
              <div className="row mt-5 freeman-block">
                <div className="col-md-6">
                  <img className="img-fluid" src={freeman} alt="freeman"/>
                </div>
                <div className="col-md-6 text-left">
                  <h1 className="section-title gradient-text">FREEMAN <br /> WORLD FAIR</h1>
                  <p>In September 2023, Lions Not Sheep is hosting the FreeMan World’s Fair. This in-person and Metaverse live-streamed event, will become the pinnacle event promoting all things freedom. Simply put, if you combine a  music festival, a gun show, a boat, a car, and a motorcycle show, with the best companies, amazing speakers, and Freedom all wrapped up into a 2-day event you would have an idea of what the Fair is all about. Your Lions Not Sheep NFT as it is your entrance ticket for the next THREE YEARS!</p>
                  <a className="btn btn-lg gradient-btn mint-btn" href="https://discord.com/invite/lionsnotsheep" target="_blank">
                    <i className="fab fa-discord"></i> JOIN OUR DISCORD
                  </a>
                </div>
              </div>
              <div className="text-center pt-3" style={{ overflow: "hidden" }}>
                <h1 className="gradient-text section-subtitle">ADDITIONAL UTILITY</h1>
                <div
                  className="slider-content"
                  style={{ flexDirection: "column" }}
                >
                  <Slider {...settings}>{sliderArray}</Slider>
                </div>
              </div>

              {/* <JoinButton /> */}
            </div>
            <div className="clearfix"></div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
