import hel from "../../assets/images/rarity_hel.png";
import lion from "../../assets/images/rarity-lion.png";

import {motion} from 'framer-motion/dist/framer-motion';

export const Rarity = () => {
  const rarity_types = [
    { sum: "17", des: "Background" },
    { sum: "1", des: "Body" },
    { sum: "5", des: "Chest Tattoo" },
    { sum: "8", des: "Arm Tattoo" },
    { sum: "11", des: "Pants" },
  ];
  const rarity_variations = [
    { sum: "11", des: "T-Shirt" },
    { sum: "26", des: "T-Logo" },
    { sum: "12", des: "Neckwear" },
    { sum: "37", des: "Button-Up" },
    { sum: "19", des: "Eyes" },
    { sum: "65", des: "Mane" },
    { sum: "40", des: "Mouth" },
    { sum: "20", des: "Eyewear" },
    { sum: "52", des: "Hands" },
  ];
  return (
    <>
    <div id="collections" className="bg-block">

    </div>
    <div id="rarity" className="rarity bg-block container-fluid">
      <div className="section-body">
        <div className="row">
          <div className="col-md-5">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5, type: "spring", stiffness: 100 }}
            viewport={{ once: true, amount: 0 }}
          >
            <h1 className="section-title gradient-text mt-5">LNS RARITY & TRAITS</h1>
            <p className="mt-5">Lions Not Sheep NFT in partnership with Meta Labs Agency has created a one of one design system that creates more variations and more uniqueness to the artistic process than any other project out there. As a result the community receives a 1 of 1 NFT with every mint. There are 1,776 digital collectible NFTs designed with 14 categories and over 324 character traits of varying rarity creating completely unique Lions Not Sheep NFTs. </p>
            <p className="mt-5">Future updates to the ecosystem and airdrops to NFT owners will further distinguish these one of a kind LNS NFTs in both appearance and capabilities.</p>
            <img className="rarity-hel hidden-sm hidden-xs" alt="rarity-hel" src={hel}/>
          </motion.div>
          </div>
          <div className="col-md-7">
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.8, type: "spring", stiffness: 100 }}
              viewport={{ once: true, amount: 0 }}
            >
            <div>
            </div>
              <div className="rarity-table">
                <div className="rarity-table-body">
                  <div className="row">
                    <h3 className="title1" style={{marginLeft:"15px"}}>TYPES</h3>
                    <div className="col-sm-6">
                      {
                        rarity_types.map((e,i) => {
                          return(
                            <div className="rarity-card" key={i.toString()}>
                              <div className="row">
                                <div className="col-xs-3 text-center"><span className="rarity-card-no">{e.sum}</span></div>
                                <div className="col-xs-9">{e.des}</div>
                              </div>
                            </div>
                          );
                        })
                      }
                      <img className="rarity-hunter hidden-xs" src={lion} alt="rarity-lion"/>
                    </div>
                    <div className="col-sm-6">
                      {/* <h3 className="title2">Rarity Traits</h3> */}
                      {
                        rarity_variations.map((e,i) => {
                          return(
                            <div className="rarity-card" key={i.toString()}>
                              <div className="row">
                                {/*<div className="col-xs-3 text-center">{e.sum}</div>*/}
                                <div className="col-xs-3 text-center"><span className="rarity-card-no">{e.sum}</span></div>
                                <div className="col-xs-9">{e.des}</div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                    <img className="rarity-hunter visible-xs" src={lion} alt="rarity-lion"/>
                  </div>
                </div>
              </div>
              </motion.div>
            </div>
        </div>
      </div>
    </div>
    </>
  );
};