import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import { onCheckMintable, onGetMintData, onGetTotalToken } from "../../redux/actions/mint";
import CounterInput from "react-counter-input";
import opensea_icon from "../../assets/images/social icon.png";
import discord_icon from "../../assets/images/discord_icons.png";
import lnsImg from "../../assets/images/mint/default.gif";
import master_logo from "../../assets/images/mint/card.png";
import visa_logo from "../../assets/images/mint/visacard.png";
import logo from "../../assets/images/logo.png";
import sub_logo from "../../assets/images/sub-logo.png";
import './mint.css';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

export const MintSection = (props) => {
  const dispatch = useDispatch();
  const { preLoading, setPreLoading, account, metamaskConnected } = props;
  const mintable = useSelector((state) => state.mint);
  const [selectedCount, setSelectedCount] = useState(1);
  const [mintLoading, setMintLoading] = useState(false);

  useEffect(async () => {
    if (mintable.count || mintable.failedMsg) {
      setPreLoading(false);
    }
    if (mintable.failedMsg) {
      setMintLoading(false);
    }
  }, [mintable]);

  useEffect(() => {
    dispatch(onGetTotalToken());
   }, [selectedCount]);

  return (
    <>
      <div id="mint" className="mint">
        <div className="intro bg-block">
          <div className="text-center">
            <img src={sub_logo} className="mint-logo-img" alt="purpose_logo"></img>
            <h1 className="gradient-text section-subtitle">
              MINT YOUR NFT
            </h1>
            <h1 className="main-title">LIONS NOT SHEEP</h1>
          </div>
        <div className="mint-content">
          {preLoading ? (
            <div className="d-flex align-items-center justify-center" style={{minHeight: "80vh"}}>
              <ReactLoading
                type="spin"
                color="#c17717"
                height={120}
                width={120}
              />
            </div>
          ) : (
              <>
                <div className="row">
                  <div className="col-md-6 text-center mt-3">
                    <img src={lnsImg} className="img-fluid mint-lions" alt="preview-bounty"/>
                  </div>
                    <div className="col-md-6 text-center">
                    {mintable.soldout? (
                      <>
                        <h1 className="soldout-title mt-5">Congratulations and Welcome to the LNS Family!</h1>
                        <a href="https://opensea.io/collection/lionsnotsheep" target="_blank" className="btn border-btn mt-5 mr-2 opensea-btn-success" rel="noreferrer">
                          <img src={opensea_icon} alt="opensea-icon" /> VIEW ON OPENSEA
                        </a>
                        <a href="https://discord.com/invite/lionsnotsheep" target="_blank" className="btn btn-lg gradient-btn mt-5" rel="noreferrer">
                          <img src={discord_icon} alt="discord-icon" /> JOIN OUR DISCORD
                        </a>
                      </>
                      ) : (
                      <>
                        <h1 className="text-center gradient-text section-subtitle">WELCOME LION</h1>
                        <div className="mint-body text-left">
                          <div>
                            <div className="flex-1">Available</div>
                            <div className="mint-value">{parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken}</div>
                          </div>
                          <div>
                            <div className="flex-1">Balance</div>
                            <div className="mint-value">{process.env.REACT_APP_PRICE} ETH</div>
                          </div>
                          <div>
                            <div className="flex-1">Amount</div> 
                            <CounterInput
                              className="amount-input"
                              count={selectedCount}
                              min={1}
                              max={parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken > 3 ? 3 : parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken}
                              onCountChange={count => {
                                setSelectedCount(count);
                              }}
                            />
                            { parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken > 0 ? (
                              <div className="flex-1 text-right"><button className="btn-max" onClick={()=> {setSelectedCount(parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken>3 ? 3 : parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken);}}>max</button></div> 
                              ) : (
                                <div className="flex-1"></div>
                              )
                            }
                          </div>
                          <div>
                              <div className="flex-1">Total</div> 
                              <div className="mint-value">{(process.env.REACT_APP_PRICE * selectedCount).toFixed(1)} ETH</div>
                          </div>
                          <div className="d-flex align-items-center justify-center">
                          {mintLoading ? (
                              <div className="loading">
                                <ReactLoading type="spin" color="#c17717" />
                              </div>
                            ) : (
                              <>
                                {parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken >= selectedCount ? (
                                  <>
                                    <CrossmintPayButton
                                        collectionTitle="Lions Not Sheep NFT"
                                        collectionDescription="Lions Not Sheep is a global invite-only community built for the leaders of the world consisting of 1776 Unique Digital Collectibles on the Ethereum blockchain. Becoming an LNS holder you are unlocking the door to getting your home in order. No matter your background, experience or walk of life, as a Lions Not Sheep NFT holder you will come out on top with your team behind you."
                                        collectionPhoto="https://lh3.googleusercontent.com/YyqGnEPKKFNIriNolAdyT-kkjc7xNq7tweMbKffpgdrX4jZijNpktNYKA1BWDZeTUJhhDPoKnE-XG8PoUWZZzojrh5gU_le_UA_uPH8=w600"
                                        clientId="baf9ec0c-4252-4742-8644-650560bef2b6"
                                        mintConfig={{
                                          "type":"erc-721",
                                          "totalPrice": (process.env.REACT_APP_PRICE * selectedCount).toFixed(2),
                                          "amount": selectedCount
                                        }}
                                        className="crossmint-button credit-btn"
                                    />
                                    <div className="text-center">
                                      <img className="creditcard-img" src={master_logo} alt="master-card" style={{width: "300px", maxWidth:"100%"}} />
                                    </div>
                                    <p>You can mint 3 NFTs at one time. There is not a limit to how many times you can mint.</p>
                                  </>
                                ) : (
                                  <>
                                    <button className="btn btn-mint disable gradient-btn">Unavailable</button>
                                    {
                                     <p>Collection is sold out.</p>
                                    }
                                  </>
                                )
                                }
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )} 
                    </div>
                  </div>
                </>
          )}
        </div>
        </div>
      </div>
    </>
  );
};
