import Faq from "react-faq-component";
import {motion} from 'framer-motion/dist/framer-motion';

import sub_logo from "../../assets/images/sub-logo.png";

export const Faqs = (props) => {
  const faq_array1 = {
    title: "",
    rows: [
        {
            title: "What is LIONS NOT SHEEP NFTS?",
            content: "<p>Lions Not Sheep is a global invite-only community built for the leaders of the world. Becoming an LNS holder you are unlocking the door to getting your home in order. No matter your background, experience or walk of life, as a Lions Not Sheep NFT holder you will come out on top with your team behind you.</p>",
        },
        {
            title: "WHEN IS THE LAUNCH DATE",
            content: "<p>The Lions Not Sheep will start the whitelist May 16, 2022</p>",
        },
        // {
        //     title: "WHAT ARE THE REFLECTION REWARDS?",
        //     content: "<p>When an LNS NFT is purchased, the commitment to the community is that 80% of the proceeds from the purchase will be contributed into a dedicated reflection pool for the LNS Community via a smart contract.</p><p>Through the collective contribution that the community creates, a yield is realized, reflecting back to the NFT holders and members of the LNS community. The monthly yield is sent out directly to the community through reflection rewards via a smart contract.</p><p>To learn more about the reflection rewards - <a href='https://Lionsnotsheepnft.io/rewards' rel='noreferrer'>https://Lionsnotsheepnft.io/rewards</a></p>",
        // },
        {
          title: "What IS CRYPTOCURRENCY?",
          content: "<p>Cryptocurrency is decentralized digital money that’s based on blockchain technology. You may be familiar with the most popular versions, Bitcoin and Ethereum, but there are more than 5,000 different cryptocurrencies in circulation.<p> <p>Learn more at Forbes - <a href='https://forbes.com/advisor/investing/what-is-cryptocurrency/' target='_blank' rel='noreferrer'>forbes.com/advisor/investing/what-is-cryptocurrency/</a><p>",
        },
        {
          title: "WHAT IS THE BLOCKCHAIN?",
          content: "<p>Blockchain is a system of recording information in a way that makes it difficult or impossible to change, hack, or cheat the system. A blockchain is essentially a digital ledger of transactions that is duplicated and distributed across the entire network of computer systems on the blockchain.</p> <p>Learn more at Investopedia - <a href='https://www.investopedia.com/terms/b/blockchain.asp' target='_blank' rel='noreferrer'>https://www.investopedia.com/terms/b/blockchain.asp</a></p>",
        },
        {
            title: "WHAT IS A NFT?",
            content: "<p>A non-fungible token is a unique and non-interchangeable unit of data stored on a digital ledger. NFTs can be used to represent easily-reproducible items such as photos, videos, audio, and other types of digital files as unique items, and use blockchain technology to establish a verified and public proof of ownership.</p> <p>Learn more at CNN - <a href='https://www.cnn.com/2021/03/17/business/what-is-nft-meaning-fe-series/index.html' target='_blank' rel='noreferrer'>https://www.cnn.com/2021/03/17/business/what-is-nft-meaning-fe-series/index.html</a></p>",
        },
        {
          title: "WHAT IS ETHEREUM?",
          content: "<p>Ethereum is a platform powered by blockchain technology that is best known for its native cryptocurrency, called ether, or ETH, or simply ethereum. The distributed nature of blockchain technology is what makes the Ethereum platform secure, and that security enables ETH to accrue value.</p><p>Learn more at Investopedia - <a href='https://www.investopedia.com/terms/e/ethereum.asp' target='_blank' rel='noreferrer'>https://www.investopedia.com/terms/e/ethereum.asp</a><p>",
        },
        {
          title: "HOW DO I GET A CRYPTO WALLET?",
          content: "<p>A crypto wallet refers to a virtual currency wallet that is accessible online, and it facilitates cryptocurrency transactions between the owner and end-users. A collection of private keys stored on a program connected to the internet is used to store and send different currencies such as Ethereum, Bitcoin, etc.</p><p>There are two wallets you will need. One to purchase the cryptocurrency ETH (we recommend Coinbase) and the other to purchase the NFT (we recommend Metamask).</p>",
        },
    ],
  };
  const faq_array2 = {
    title: "",
    rows: [
      {
        title: "HOW DO I BUY ETHEREUM?",
        content: "<p>There are many secure options to purchase Ethereum:</p><p>Coinbase - <a href='https://www.coinbase.com/' target='_blank' rel='noreferrer'>https://www.coinbase.com/</a></p><p>Gemini - <a href='https://www.gemini.com/' target='_blank' rel='noreferrer'>https://www.gemini.com/</a></p><p>Crypto.com - <a href='https://crypto.com/' target='_balnk' rel='noreferrer'>https://crypto.com/</a></p><p>Learn more at Time - <a href='https://time.com/nextadvisor/investing/cryptocurrency/how-to-buy-ethereum/' target='_blank' rel='noreferrer'>https://time.com/nextadvisor/investing/cryptocurrency/how-to-buy-ethereum/</a></p>",
      },
      {
        title: "HOW DO I GET A METAMASK ACCOUNT?",
        content: "<p>MetaMask is a web browser extension that allows you to manage your Ethereum private keys via your web browser. By doing so, it serves as a wallet for Ether and ERC-20 tokens, and allows you to visit the distributed web of tomorrow in your browser today. To be more specific, it allows you to run Ethereum Dapps (Decentralized Apps) right in your browser without running a full Ethereum node. (In other words, you don't have to download and sync the full blockchain on your device or computer). Our mission is to make Ethereum as easy to use for as many people as possible.</p><p>What is MetaMask - <a href='https://youtu.be/YVgfHZMFFFQ' target='_blank' rel='noreferrer'>https://youtu.be/YVgfHZMFFFQ</a></p> <p>How to install MetaMask on all devices and browsers: <a href='https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask' target='_blank' rel='noreferrer'>https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask</a></p>"
      },
      {
        title: "HOW DO I TRANSFER ETHEREUM TO MY METAMASK ACCOUNT?",
        content: "<p>From Coinbase and Gemini <a href='https://docs.everbloom.co/article/255jk54yda-step-three-sending-your-eth-to-your-walletM' target='_blank' rel='noreferrer'>https://docs.everbloom.co/article/255jk54yda-step-three-sending-your-eth-to-your-walletM</a></p> <p>Coinbase to Metamask desktop - <a href='https://www.youtube.com/watch?v=A_j9feqBgnc' target='_blank' rel='noreferrer'>https://www.youtube.com/watch?v=A_j9feqBgnc</a></p> <p>Coinbase to Metamask phone - <a href='https://www.youtube.com/watch?v=UtMdutkCoQI' target='_blank' rel='noreferrer'>https://www.youtube.com/watch?v=UtMdutkCoQI</a><p>Crypto.com to Metamask phone -<br /><a href='https://www.youtube.com/watch?v=osqMhR6bt_Y' target='_blank' rel='noreferrer'>https://www.youtube.com/watch?v=osqMhR6bt_Y</a><br /><a href='https://www.youtube.com/watch?v=6NsX7jTvt3k' target='_blank' rel='noreferrer'>https://www.youtube.com/watch?v=6NsX7jTvt3k</a></p>",
      },
      {
        title: "HOW DO I PURCHASE A  Lions Not Sheep NFT?",
        content: "<p>Will be released in full detail soon.</p>",
      },
      {
        title: "HOW MANY MINTABLE NFTS ARE THERE?",
        content: "<p>Lions Not Sheep will be 1,776 Unique NFTs.</p>",
      },
      // {
      //   title: "How and when will my direct and affiliate reflections be sent out?",
      //   content: "<p>All reflection rewards are sent out monthly unless you would like to hold them at 3 months, 6 months, or 12 months.</p><p>Direct Reflection Rewards are sent out every month starting between weeks eight and nine of joining the Lions Not Sheep Community.</p>",
      // },
      {
        title: "HOW DO I TRACK MY REWARDS?",
        content: "<p>There will be a Lions Not Sheep Vault  where you can check and verify all of your rewards and track your affiliates.</p>",
      },
      {
        title: "Can I purchase or sell a Lions Not Sheep NFT on OpenSea?",
        content: "<p>Once a person purchases the Lions Not Sheep NFT, all future transactions happen on a secondary market, such as Opensea.io.</p><p>OpenSea is the first and largest peer-to-peer marketplace for cryptogoods (like an eBay for crypto assets), which include collectibles, gaming items, and other virtual goods backed by a blockchain. On OpenSea, anyone can buy or sell these items through a smart contract.</p>",
      },
    ]
  };
  const styles = {
    bgColor: 'rgb(255, 255, 255, 0)',
    titleTextColor: "rgb(162, 243, 242)",
    rowTitleColor: "rgb(162, 243, 242)",
    rowContentColor: 'rgb(162, 243, 242)',
    arrowColor: "red",
  };
  const config = {
    animate: true,
    arrowIcon: "+",
    // tabFocus: true
  };
  return (
    <div id="faqs" className="faq bg-block text-center">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.5, type: "spring", stiffness: 100 }}
        viewport={{ once: true, amount: 0 }}
      >
      <img src={sub_logo} alt="faq_logo"></img>
      <h1 className="section-title gradient-text">FREQUENTLY ASKED</h1>
      <h1 className="section-title gradient-text">QUESTIONS</h1>
      <div className="section-body">
          <div className="col-md-6">
            <Faq 
              data={faq_array1}
              styles={styles}
              config={config}
            />
          </div>
          <div className="col-md-6">
            <Faq 
              data={faq_array2}
              styles={styles}
              config={config}
            />
          </div>
      </div>
      </motion.div>
    </div>
  );
};
