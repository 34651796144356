import SmoothScroll from "smooth-scroll";

const Comingsoon = (props) => {
  return (
    <div className="loading">
      
    </div>
  );
};

export default Comingsoon;