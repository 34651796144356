import { REFERRERID_CHECK_SUCCESS, REFERRERID_CHECK_FAILED, AFFILIATE_CHECK_SUCCESS, AFFILIATE_CHECK_FAILED, REFERRALID_CHECK_SUCCESS, REFERRALID_CHECK_FAILED } from "../shared/ActionTypes.js"
import authAxios from '../apis/axios'
import swal from 'sweetalert';

export const onReferrerIDVerify = ({
    username
}) => {
    return async(dispatch) => {
        const body = {
            username
        };
        try {
            const res = await authAxios.post("/CheckUsername", body);
            if (res.data.status == "failed") {
                swal("Sorry!", res.data.msg, "error");
            } else if (res.data.status == "success") {
                if (res.data.msg == "found") {
                    dispatch({
                        type: REFERRERID_CHECK_SUCCESS,
                        payload: {
                            referrerID: res.data.mdetails.username,
                            parent_id: res.data.mdetails.member_id
                        },
                    });
                } else {
                    dispatch({
                        type: REFERRERID_CHECK_FAILED,
                        payload: {
                            errorMsg: res.data.msg
                        },
                    });
                }
            }
        } catch (err) {
            console.log('error!!!!', err)
        }
    }
}

export const onNewReferralIDVerify = ({
    username
}) => {
    return async(dispatch) => {
        const body = {
            username
        };
        try {
            const res = await authAxios.post("/CheckUsername", body);
            if (res.data.status == "failed") {
                swal("Sorry!", res.data.msg, "error");
            } else if (res.data.status == "success") {
                if (res.data.msg == "notfound") {
                    dispatch({
                        type: REFERRALID_CHECK_SUCCESS,
                        payload: {
                            referralID: body.username
                        },
                    });
                } else {
                    dispatch({
                        type: REFERRALID_CHECK_FAILED,
                        payload: {
                            errorMsg: "username already exist"
                        },
                    });
                }
            }
        } catch (err) {
            console.log('error!!!!', err)
        }
    }
}

/* export const onCheckUserAffiliate = ({
    metamask_id,
    parent_id
}) => {
    return async(dispatch) => {
        const body = {
            metamask_id,
        };
        try {
            const res = await authAxios.post("/CheckMetaMask", body);
            if (res.data.status == "failed") {
                swal("Sorry!", res.data.msg, "error");
            } else if (res.data.status == "success") {
                if (res.data.msg == "notfound") {
                    const body1 = {
                        metamask_id,
                        parent_id
                    };
                    const res = await authAxios.post("/AddMetaMask", body1);
                    if (res.data.status == "success") {
                        dispatch({
                            type: AFFILIATE_CHECK_SUCCESS,
                            payload: {
                                checkedDetail: res.data.mdetails,
                            },
                        });
                    } 
                } else {
                    dispatch({
                        type: AFFILIATE_CHECK_SUCCESS,
                        payload: {
                            checkedDetail: res.data.mdetails,
                        },
                    });
                }
            }

        } catch (err) {
            console.log('error!!!!', err)
        }
    }
} */

export const onCheckUserAffiliate = ({
    metamask_id
}) => {
    return async(dispatch) => {
        const body = {
            metamask_id,
        };
        try {
            const res = await authAxios.post("/CheckMetaMask", body);
            if (res.data.status == "failed") {
                swal("Sorry!", res.data.msg, "error");
            } else if (res.data.status == "success") {
                if (res.data.msg == "found") {
                    dispatch({
                        type: AFFILIATE_CHECK_SUCCESS,
                        payload: {
                            referralID: res.data.mdetails.username,
                            referrerID: res.data.mdetails.parentusername,
                            parent_id: res.data.mdetails.parent_id
                        },
                    });
                } else {
                    dispatch({
                        type: AFFILIATE_CHECK_FAILED,
                        payload: {
                            errorMsg: res.data.msg
                        },
                    });
                }
            }
        } catch (err) {
            console.log('error!!!!', err)
        }
    }
}

/* export const onNewReferralIDVerify = ({
    username,
    member_id
}) => {
    return async(dispatch) => {
        const body = {
            username,
            member_id
        };
        // console.log(body);
        try {
            const res = await authAxios.post("/AddUserName", body);
            if (res.data.status == "error") {
                dispatch({
                    type: CREATE_REFERAL_ID_FAILED,
                    payload: {
                        errorMsg: res.data.msg
                    }
                })
            } else if (res.data.status == "failed") {
                swal("Sorry!", res.data.msg, "error");
            } else {
                if (res.data.msg == "notfound") {
                    // swal("Sorry!", "You are not registered", "error");
                } else {
                    dispatch({
                        type: AFFILIATE_CHECK_SUCCESS,
                        payload: {
                            checkedDetail: res.data.mdetails,
                        },
                    });
                }
            }

        } catch (err) {
            console.log('error!!!!', err)
        }
    }
} */