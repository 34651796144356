import { Navbar } from "../components/navbar";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../components/claim/header";
import { ClaimSection } from "../components/claim/claimSection";
import { SubFooter } from "../components/sub_footer2";
import SmoothScroll from "smooth-scroll";
import { onCheckRewards } from "../redux/actions";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Claim = (props) => {
  const dispatch = useDispatch();
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const [preLoading, setPreLoading] = useState(false);
  useEffect(() => {
    if (account) {
      setPreLoading(true);
      dispatch(
        onCheckRewards({
          address: account,
        })
      );
    }
  }, [account]);

  return (
    <div>
      <Navbar
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />
      <Header 
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />
      <ClaimSection
        setPreLoading={setPreLoading}
        preLoading={preLoading}
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
        />
      <SubFooter />
    </div>
  );
};

export default Claim;
