import { Navbar } from "../components/navbar";
import { Header } from "../components/home/header";
import { Who } from "../components/home/who";
import { Why } from "../components/home/why";
import { Ready } from "../components/home/ready";
import { Utility } from "../components/home/utility";
import { Roadmap } from "../components/home/roadmap";
import { Rarity } from "../components/home/rarity";
import { Faqs } from "../components/home/faqs";
import { Founders } from "../components/home/founders";
import { Footer } from "../components/footer";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Main = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  return (
    <div>
      <Navbar
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />
      <Header />
      <Who />
      <Why />
      <Ready />
      {/* <Roadmap /> */}
      {/* <Utility /> */}
      <Rarity />
      <Faqs />
      <Founders />
      <Footer />
    </div>
  );
};

export default Main;
