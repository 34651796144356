//API
export const BALANCE_CHECK_SUCCESS = 'balance_check_success';
export const REFERRERID_CHECK_SUCCESS = 'referrerID_check_success';
export const REFERRERID_CHECK_FAILED = 'referrerID_check_failed';
export const REFERRALID_CHECK_SUCCESS = 'referralID_check_success';
export const REFERRALID_CHECK_FAILED = 'referralID_check_failed';
export const DISCORD_CHECK_SUCCESS = 'discord_check_success';
export const RETWEET_CHECK_SUCCESS = 'retweet_check_success';
export const SIGNUP_SUCCESS = 'signup_success';
export const USER_CHECK_SUCCESS = 'check_user_success';
export const CHECK_MINTABLE_SUCCESS = 'check_mintable_success';
export const CHECK_MINTABLE_FAILED = 'check_mintable_failed';
export const GET_MINTDATA_SUCCESS = 'get_mintdata_success';
export const GET_MINTDATA_FAILED = 'get_mintdata_failed';
export const AFFILIATE_CHECK_SUCCESS = 'affiliate_check_success';
export const AFFILIATE_CHECK_FAILED = 'affiliate_check_failed';
export const CREATE_REFERAL_ID_FAILED = 'create_referal_id_failed';
export const UPDATE_REFERRERID_FAILED = 'update_referrer_id_failed';
export const GET_TOTALTOKEN_SUCCESS = 'get_totaltoken_success';
export const METADATA_CHECK_SUCCESS = 'metadata_check_success';
export const METADATA_CHECK_FAILED = 'metadata_check_failed';
export const CHECK_REWARDS_SUCCESS = 'check_rewards_success';
export const CHECK_REWARDS_FAILED = 'check_rewards_failed';
export const GET_CLAIMDATA_SUCCESS = 'get_claimdata_success';
export const GET_CLAIMDATA_FAILED = 'get_claimdata_failed';
export const USERINFO_GET_FAILED = 'get_userinfo_failed';
export const USERINFO_GET_SUCCESS = 'get_userinfo_success';

export const VP_USER_CHECK_SUCCESS = 'verifyplususer_check_success';
export const VP_USER_SIGNUP_SUCCESS = 'verifyplususer_signup_success';
export const PHONE_CHECK_SUCCESS = 'phone_check_success';
export const EMAIL_CHECK_SUCCESS = 'email_check_success';
