import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { onCheckMintable, onGetMintData, setHistory } from "../../redux/actions/mint";
import CounterInput from "react-counter-input";
import { mintNft } from "../../web3/web3";
import Modal from "react-modal";
import opensea_icon from "../../assets/images/social icon.png";
import discord_icon from "../../assets/images/discord_icons.png";
import lnsImg from "../../assets/images/mint/default.gif";
import logo from "../../assets/images/logo.png";
import cross_logo from "../../assets/images/mint/cross-logo.png";
import sub_logo from "../../assets/images/sub-logo.png";
import swal from "sweetalert";
import './mint.css';
import { HashLink } from "react-router-hash-link";

export const MintSection = (props) => {
  const dispatch = useDispatch();
  const { preLoading, setPreLoading, account, metamaskConnected } = props;
  const mintable = useSelector((state) => state.mint);
  const [selectedCount, setSelectedCount] = useState(1);
  const [mintLoading, setMintLoading] = useState(false);
  const [mintStatus, setMintStatus] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [networkId, setNetworkId] = useState();

  useEffect(async () => {
    if(metamaskConnected){
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();
      setNetworkId(networkId);
      window.ethereum.on("networkChanged", (networkId) => {
        setNetworkId(networkId);
      });
    }
  });

  useEffect(async () => {
    if (mintable.count || mintable.failedMsg) {
      setPreLoading(false);
    }
    if (mintable.failedMsg) {
      setMintLoading(false);
    }
    if (mintable.mintData.success == true) {
      const price = mintable.mintData.price;
      const tokenAmount = mintable.mintData.tokenAmount;
      const timestamp = mintable.mintData.timestamp;
      const signature = mintable.mintData.signature;
      await mintNft(price, tokenAmount, timestamp, signature, account).then(
        (data) => {
          setMintStatus(data.success);
          if (data.success == false) {
            if (data.type == "estimategas") {
              swal(
                "Sorry",
                `Insufficient funds to mint. Please consider the price ${(mintable.mintData.price * selectedCount).toFixed(2)}Eth, and some gas fee!`,
                "warning"
              );
            } else if (data.type == "mint") {
              swal("Sorry", "Error happened while processing, please try again later", "warning");
            }
          }
          setMintLoading(false);
        }
      );
    } else {
      setMintLoading(false);
    }
  }, [mintable]);

  useEffect(() => {
    if (mintStatus) {
      // console.log('historyId', mintable.mintData.historyId);
      dispatch(setHistory({address: account, historyId: mintable.mintData.historyId}))
      setViewModal(true);
      dispatch(
        onCheckMintable({
            address: account,
        })
      );
    }
  }, [mintStatus]);

  const handleMint = () => {
    setMintLoading(true);
    dispatch(onGetMintData({ address: account, count: selectedCount }));
  };

  const handleClose = () => {
    setViewModal(false);
  };

  return (
    <>
      <div id="mint" className="mint">
        <div className="intro bg-block">
          <div className="text-center">
            <img src={sub_logo} className="mint-logo-img" alt="purpose_logo"></img>
            <h1 className="gradient-text section-subtitle">
              MINT YOUR NFT
            </h1>
            <h1 className="main-title">LIONS NOT SHEEP</h1>
          </div>
        <div className="mint-content">
          {preLoading ? (
            <div className="d-flex align-items-center justify-center" style={{minHeight: "80vh"}}>
              <ReactLoading
                type="spin"
                color="#c17717"
                height={120}
                width={120}
              />
            </div>
          ) : (
              <>
                <div className="row">
                  <div className="col-md-6 text-center mt-3">
                    <img src={lnsImg} className="img-fluid mint-lions" alt="preview-bounty"/>
                  </div>
                    <div className="col-md-6 text-center">
                    {mintable.soldout? (
                      <>
                        <h1 className="soldout-title mt-5">Congratulations and Welcome to the LNS Family!</h1>
                        <a href="https://opensea.io/collection/lionsnotsheep" target="_blank" className="btn border-btn mt-5 mr-2 opensea-btn-success" rel="noreferrer">
                          <img src={opensea_icon} alt="opensea-icon" /> VIEW ON OPENSEA
                        </a>
                        <a href="https://discord.com/invite/lionsnotsheep" target="_blank" className="btn btn-lg gradient-btn mt-5" rel="noreferrer">
                          <img src={discord_icon} alt="discord-icon" /> JOIN OUR DISCORD
                        </a>
                      </>
                      ) : (
                      <>
                        <h1 className="text-center gradient-text section-subtitle">WELCOME LION</h1>
                        <div className="mint-body text-left">
                          <div>
                            <div className="flex-1">Available</div>
                            <div className="mint-value">{parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken}</div>
                          </div>
                          <div>
                            <div className="flex-1">Balance</div>
                            <div className="mint-value">{mintable.price?mintable.price:""} ETH</div>
                          </div>
                          <div>
                            <div className="flex-1">Amount</div> 
                            <CounterInput
                              className="amount-input"
                              count={selectedCount}
                              min={1}
                              max={mintable.count?mintable.count:1}
                              onCountChange={count => {
                                setSelectedCount(count);
                              }}
                            />
                            {mintable.count? (
                              <div className="flex-1 text-right"><button className="btn-max" onClick={()=> {setSelectedCount(mintable.count);}}>max</button></div> 
                            ) : (
                              <div className="flex-1"></div>
                            )}
                          </div>
                          <div>
                              <div className="flex-1">Total</div> 
                              <div className="mint-value">{mintable.price?(mintable.price * selectedCount).toFixed(2):""} ETH</div>
                          </div>
                          <div className="d-flex align-items-center justify-center">
                          {mintLoading ? (
                              <div className="loading">
                                <ReactLoading type="spin" color="#c17717" />
                              </div>
                            ) : (
                              <>
                                {networkId == process.env.REACT_APP_NETWORK_ID && mintable.count ? (
                                  <>
                                  <button
                                    className="btn btn-mint gradient-btn"
                                    onClick={handleMint}
                                  >
                                    MINT
                                  </button>
                                  <p>You can mint up to 30 NFTs at one time. There is not a limit to how many times you can mint.</p>
                                  </>
                                ) : (
                                  <>
                                    <button className="btn btn-mint disable gradient-btn">Unavailable</button>
                                    {
                                      mintable.failedMsg ? (
                                        <p>{mintable.failedMsg}</p>
                                      ) : (
                                        <p>Make sure your Metamask wallet is connected.</p>
                                      )
                                    }
                                  </>
                                )
                                }
                              </>
                            )}
                            <br />
                            <HashLink smooth to="/mintCross#"
                              className="btn btn-mint gradient-btn credit-btn"
                              style={{width: "auto"}}
                              onClick={handleMint}
                            >
                              <img src={cross_logo} alt="cross logo" style={{width: "20px", marginTop: "-5px"}}/>
                              MINT WITH CREDIT/DEBIT CARD
                            </HashLink>
                          </div>
                        </div>
                      </>
                    )} 
                    </div>
                  </div>
                </>
          )}
        </div>
        </div>
      </div>
      <Modal
        appElement={document.getElementById('root')}
        isOpen={viewModal}
        onRequestClose={handleClose}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "overlay-after",
          beforeClose: "overlay-before",
        }}
        className={{
          base: "content-base",
          afterOpen: "content-after",
          beforeClose: "content-before",
        }}
        closeTimeoutMS={500}
      >
        <div className="mint-logo">
          <Link to="/" rel="nofollow">
            <img src={logo} alt="lns-logo" />
          </Link>
        </div>
        <div className="close">
          <i className="fa close-icon" onClick={handleClose}>
            &#xf00d;
          </i>
        </div>
        <div className="success-msg">
          <h1>CONGRATULATIONS!</h1>
          <p>YOU HAVE SUCCESSFULLY MINTED YOUR <nobr><strong>LIONS NOT SHEEP NFT</strong></nobr>!</p>
        </div>
        <div style={{ marginTop: "8vh" }}>
          <a href="https://opensea.io/collection/lionsnotsheep" target="_blank" className="btn border-btn mt-5 mr-2 opensea-btn-success" rel="noreferrer">
            <img src={opensea_icon} alt="opensea-icon" /> VIEW ON OPENSEA
          </a>
          <a href="https://discord.com/invite/lionsnotsheep" target="_blank" className="btn btn-lg gradient-btn mt-5" rel="noreferrer">
            <img src={discord_icon} alt="discord-icon" /> JOIN OUR DISCORD
          </a>
        </div>
      </Modal>
    </>
  );
};
