import { Navbar } from "../components/navbar";
import { Header } from "../components/vault/header";
import { VaultSection } from "../components/vault/vaultSection";
import { SubFooter } from "../components/sub_footer2";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Vault = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  return (
    <div>
      <Navbar
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />
      <Header 
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />
      {
        account && (
          <VaultSection 
            metamaskConnected={metamaskConnected}
            setMetamaskConnnected={setMetamaskConnnected}
            account={account}
          />
        )
      }
      <SubFooter page="vault" />
    </div>
  );
};

export default Vault;
