import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion/dist/framer-motion";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { onGetClaimData, setClaimHistory, onCheckRewards } from "../../redux/actions/rewards";
import { claimRewards } from "../../web3/web3";
import Modal from "react-modal";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import claim_bottom from '../../assets/images/verify/verify-bottom-b.png';
import logo from "../../assets/images/logo.png";

export const ClaimSection = (props) => {
  const dispatch = useDispatch();
  const { preLoading, setPreLoading, metamaskConnected, setMetamaskConnnected, account } = props;
  const rewards = useSelector((state) => state.rewards);
  const [claimLoading, setClaimLoading] = useState(false);
  const [claimStatus, setClaimStatus] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [networkId, setNetworkId] = useState();
  
  const handleConnectWallet = async () => {
    if (window.ethereum) {
      await window.ethereum.enable();
      setMetamaskConnnected(true);
    } else {
      window.open('https://metamask.io/', '_blank');
    }
  };

  useEffect(async () => {
    if(metamaskConnected){
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();
      setNetworkId(networkId);
      window.ethereum.on("networkChanged", (networkId) => {
        setNetworkId(networkId);
      });
    }
  });
  
  useEffect(async () => {
    if (rewards.claimData || rewards.failedMsg) {
      setPreLoading(false);
    }
    if (rewards.claimData && rewards.claimData.success == true) {
      const rewardAmount = rewards.claimData.rewardAmount;
      const timestamp = rewards.claimData.timestamp;
      const signature = rewards.claimData.signature;
      await claimRewards(rewardAmount, timestamp, signature, account).then(
        (data) => {
          setClaimStatus(data.success);
          if (data.success == false) {
            if (data.type == "estimategas") {
              swal(
                "Sorry",
                `Insufficient funds to claim. Please consider some gas fee!`,
                "warning"
              );
            } else if (data.type == "claim") {
              swal("Sorry", "Error happened while processing, please try again later", "warning");
            }
          }
          setClaimLoading(false);
        }
      );
    } else {
      setClaimLoading(false);
    }
  }, [rewards]);

  useEffect(() => {
    if (claimStatus) {
      dispatch(setClaimHistory({address: account, historyId: rewards.claimData.historyId, }))
      rewards.claimData = [];
      setViewModal(true);
    }
  }, [claimStatus]);

  const handleClaim = () => {
    setClaimLoading(true);
    dispatch(onGetClaimData({ address: account }));
  };

  const handleClose = () => {
    setViewModal(false);
    dispatch(onCheckRewards({
      address: account,
    }));
  };

  return (
    <div id="claim" className="purpose">
      <div className="purpose-body1 bg-block">
        {preLoading ? (
            <div className="d-flex align-items-center justify-center claim-loading" style={{minHeight: "100vh"}}>
                <ReactLoading
                    type="spin"
                    color="#c17717"
                    height={120}
                    width={120}
                />
            </div>
        ) : (
            <>
            <div id="steps" className="container mt-5">
                <div className="step-item mt-5">
                    <motion.div
                        initial={{ opacity: 0, x: -200 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <div className="step-title"><h2>STEP 1</h2></div>
                        <div className="step-content">
                            <h3 className="mt-5">CONNECT YOUR METAMASK WALLET.</h3>
                            <p>*You may incur minor gas fees to claim your reflections from the Ethereum network.</p>
                            <div>
                            {account ? (
                                <button
                                className="btn btn-lg gradient-btn connect-btn"
                                >
                                {account &&
                                    `${account.substring(0, 6)}...${account.substring(
                                    account.length - 4
                                    )}`}
                                </button>
                            ) : (
                                <button
                                className="btn btn-lg gradient-btn connect-btn"
                                onClick={handleConnectWallet}
                                >
                                CONNECT YOUR METAMASK WALLET
                                </button>
                            )}
                            </div>
                        </div>
                    </motion.div>
                </div>
                <div className="step-item">
                    <motion.div
                        initial={{ opacity: 0, x: -200 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <div className="step-title"><h2>STEP 2</h2></div>
                        <div className="step-content">
                            <h3 className="mt-5">INITIATE YOUR REWARDS CLAIM</h3>
                            <p>*Click the 'Claim Rewards' button below to claim your current Lions Not Sheep Rewards.</p>
                            <div className="mt-5">
                                <h3><span className="eth-symbol">Ξ</span> CURRENT REWARDS TO CLAIM</h3>
                                <p className="input-info">
                                    { rewards.rewardAmount?rewards.rewardAmount:""}
                                </p>
                                <h3 className="mt-5"><span className="eth-symbol">Ξ</span> TOTAL REWARDS CLAIMED</h3>
                                <p className="input-info">
                                    { rewards.paidAmount?rewards.paidAmount:""}
                                </p>
                            </div>
                            <div className="mt-3">
                            {claimLoading ? (
                                <div className="claim-loading">
                                <ReactLoading type="spin" color="#c17717" />
                                </div>
                            ) : (
                                <>
                                {networkId == process.env.REACT_APP_NETWORK_ID && rewards.rewardAmount > 0 && !rewards.failedMsg ? (
                                    <>
                                    <button
                                     className="btn btn-lg gradient-btn connect-btn disable"
                                     /* onClick={handleClaim} */
                                    >
                                    CLAIM REWARDS
                                    </button>
                                    </>
                                ) : (
                                    <>
                                    <button className="btn btn-lg gradient-btn connect-btn disable">CLAIM REWARDS</button>
                                    {
                                        rewards.failedMsg && (
                                        <p className="failed-msg">{rewards.failedMsg}</p>
                                        )
                                    }
                                    </>
                                )
                                }
                                </>
                            )}
                            </div>
                        </div>
                    </motion.div>
                </div>
                <div className="step-item">
                    <motion.div
                        initial={{ opacity: 0, x: -200 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <div className="step-title"><h2>FAQs</h2></div>
                        <div className="step-content">
                            <h3 className="mt-5">NEED HELP CLAIMING REWARDS?</h3>
                            <p>*Upon claiming your reflections you will see a balance increase in your internal TXNs.</p>
                            <p>To see exact transaction, view your wallet address on etherscan and click the internal txns tab. Still having trouble? Watch the video below, view the FAQs and reach out to our support team on discord to help you out.</p>
                            <div className="mt-2">
                                <a href="https://twitter.com/lionsnotsheep_" target="_blank" className="url border">https://twitter.com/lionsnotsheep_</a>
                            </div>
                            <HashLink
                                className="btn btn-lg gradient-btn connect-btn"
                                to="/#faqs"
                            >
                                view our faqs
                            </HashLink>
                        </div>
                    </motion.div>
                    </div>
            </div>
            <img className="bottom-bg" src={claim_bottom} alt="claim-bottom"/>
            <Modal
                appElement={document.getElementById('root')}
                isOpen={viewModal}
                onRequestClose={handleClose}
                overlayClassName={{
                base: "overlay-base",
                afterOpen: "overlay-after",
                beforeClose: "overlay-before",
                }}
                className={{
                base: "content-base",
                afterOpen: "content-after claim",
                beforeClose: "content-before",
                }}
                closeTimeoutMS={500}
            >
                <div className="mint-logo">
                <Link to="/" rel="nofollow">
                    <img src={logo} alt="lns-logo" />
                </Link>
                </div>
                <div className="close">
                <i className="fa close-icon" onClick={handleClose}>
                    &#xf00d;
                </i>
                </div>
                <div className="success-msg">
                <h1>CONGRATULATIONS!</h1>
                <h3 className="mt-5">Reward successfully claimed. Please check your wallet!</h3>
                </div>
            </Modal>
            </>
        )}
      </div>                          
    </div>
  );
};
