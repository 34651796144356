import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { onCheckUserAffiliate } from "../redux/actions";
import { Navbar } from "../components/navbar";
import { Header } from "../components/verify/header";
import { Verify } from "../components/verify/verify";
import { VerifyPlus } from "../components/verify/verifyPlus";
import { SubFooter } from "../components/sub_footer";
import authAxios from "../redux/apis/axios";

const Signup = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const affiliate = useSelector((state) => state.affiliate);
  const [verifyPlusFlag, setVerifyPlusFlag] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (account) {
      dispatch(
        onCheckUserAffiliate({
          metamask_id: account
        })
      );
    }
  }, [account]);

  useEffect( async () => {
    if (account) {
      const res = await authAxios.post("checkUserPlus", {address: account});
      if(res.data.success == true) {
        setVerifyPlusFlag(true);
      } else {
        setVerifyPlusFlag(false);
      }
    }
}, [account]);


  return (
    <div>
      <Navbar flag={affiliate.verifyFlag}
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account} />
        <Header flag={affiliate.verifyFlag} verifyPlusFlag={verifyPlusFlag}/>
        {affiliate.verifyFlag == true ? (
        <>
          <Verify
            metamaskConnected={metamaskConnected}
            setMetamaskConnnected={setMetamaskConnnected}
            account={account}
          />
        </>
      ) : (
        <>
          {verifyPlusFlag == true && (
            <VerifyPlus
            metamaskConnected={metamaskConnected}
            setMetamaskConnnected={setMetamaskConnnected}
            account={account}
          />)}
          
        </>
      )}
      <SubFooter />
    </div>
  );
};

export default Signup;
