import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

import logo from "../assets/images/footer-logo.png";
import button_icon from "../assets/images/button-icon2.png"
import instagramIcon from '../assets/images/social-instagram.png';
import disocrdIcon from "../assets/images/social-discord.png";
import openseaIcon from "../assets/images/social-opensea.png";
import facebookIcon from "../assets/images/social-facebook.png";
import opensea_icon from "../assets/images/opensea_icon.png";

export const SubFooter = (props) => {
  const { page } = props;
  const mintable = useSelector((state) => state.mint);
  return (
    <div id="footer" className="sub-footer text-center">
      <div className="footer-content bg-block">
        <div className="section-body">
          <div className="row">
            <div className="col-md-5 text-left hidden-md hidden-sm hidden-xs">
              <h1>JOIN THE <nobr>LIONS NOT SHEEP</nobr></h1>
              <div className="btn-block">
                <a className="btn btn-lg gradient-btn mint-btn" href="https://discord.com/invite/lionsnotsheep" target="_blank"><i className="fab fa-discord"></i> JOIN OUR DISCORD</a>
                {/* <button className="btn btn-lg border-btn"><img src={opensea_icon} alt="opensea-icon"/>CHECK IT ON OPENSEA</button> */}
                <a href="https://opensea.io/collection/lionsnotsheep" target="_blank" className="btn btn-lg border-btn"><img src={opensea_icon} alt="opensea-icon"/>CHECK IT ON OPENSEA</a>
            </div>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12 p-0 text-center">
              <img className="footer-logo" src={logo} alt="footer logo"/>
            </div>
            <div className="col-md-5 hidden-md hidden-sm hidden-xs">
              <div className="btn-block center">
                {/* <a className="btn btn-lg gradient-btn mint-btn"><img src={button_icon} alt="btn-icon"/> MINT COMING SOON</a> */}
                {
                  !mintable.soldout && (
                    <HashLink smooth to="/mint#" className="btn btn-lg gradient-btn mint-btn"><img src={button_icon}></img>MINT YOUR NFT NOW</HashLink>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="footer-btn-area mt-2">
          <a className="footer-btn" href="https://www.instagram.com/lionsnotsheep/" target="_blank" rel="noreferrer"><img src={instagramIcon} alt="instagram icon" /></a>
          <a className="footer-btn" href="https://discord.com/invite/lionsnotsheep" target="_blank" rel="noreferrer"><img src={disocrdIcon} alt="discord icon" /></a>
          <a className="footer-btn" href="https://opensea.io/collection/lionsnotsheep" target="_blank" rel="noreferrer"><img src={openseaIcon} alt="opensea icon" /></a>
          <a className="footer-btn" href="https://www.facebook.com/LionsNotSheep/" target="_blank" rel="noreferrer"><img src={facebookIcon} alt="facebookIcon icon" /></a>
        </div>
        <p>©2022 <strong>LIONS NOT SHEEP</strong> NFT. ALL RIGHTS RESERVED.</p>
        <div className="text-center">
          <ul className="footer-menu">
            <li><HashLink smooth to="/policy#" className="text-white">Privacy Policy</HashLink></li>
            <li><HashLink smooth to="/terms#" className="text-white">Terms of Use</HashLink></li>
          </ul>
        </div>
      </div>
    </div>
  );
};
