import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { motion } from "framer-motion/dist/framer-motion";
import { HashLink } from "react-router-hash-link";

import { onGetMetadata, onGetUserInfo, onCheckRewards } from "../../redux/actions";
import ReactLoading from "react-loading";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import opensea_icon from "../../assets/images/opensea_icon.png";
import border_image from "../../assets/images/vault/stroke.png";

export const VaultSection = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const mintable = useSelector((state) => state.mint);
  const vaultData = useSelector((state) => state.vault);
  const rewards = useSelector((state) => state.rewards);
  const dispatch = useDispatch();
  const [metas, setMetas] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [directPayout, setDirectPayout] = useState(0);
  const [affiliatePayout, setAffiliatePayout] = useState(0);
  const [directPaidAmount, setDirectPaidAmount] = useState(0);
  const [affiliatePaidAmount, setAffiliatePaidAmount] = useState(0);

  useEffect(() => {
    if (account) {
      dispatch(
        onGetMetadata({
          address: account,
          //address: "0xE2A2d5Ee44e0Bd6332030A3d7d775f8ea59347bc",
        })
      );
      dispatch(
        onGetUserInfo({
          address: account,
          //address: "0xE2A2d5Ee44e0Bd6332030A3d7d775f8ea59347bc"
        })
      );
      dispatch(
        onCheckRewards({
          address: account,
          //address: "0xE2A2d5Ee44e0Bd6332030A3d7d775f8ea59347bc"
        })
      );
    }
  }, [account]);


  useEffect(() => {
    if(vaultData.errorMsg)
      setErrorMsg(vaultData.errorMsg);
    else {
      setMetas(vaultData.metadata);
    }
    if(vaultData.userInfoMsg || vaultData.userInfo) {
      setDashboardLoading(false);
      setUserInfo(vaultData.userInfo);
    }
  }, [vaultData]);

  useEffect(() => {
    setDirectPayout((rewards.directAmount + rewards.directPaidAmount).toFixed(3));
    setAffiliatePayout((rewards.affiliateAmount + rewards.affiliatePaidAmount).toFixed(3));
    setDirectPaidAmount((rewards.directPaidAmount).toFixed(3));
    setAffiliatePaidAmount((rewards.affiliatePaidAmount).toFixed(3));
  }, [rewards]);

  return (
    <div id="vault" className="vault text-center">
      <div className="purpose-body1 bg-block">

            <h1 className="section-title gradient-text">VIEW YOUR COLLECTION</h1>
            { errorMsg? (
                <div className="">
                  <h3 className="text-center vault-error">{errorMsg}</h3>
                  <div>
                    <HashLink smooth to="/mint#root" className="btn btn-lg gradient-btn">MINT YOUR LIONS NOT SHEEP</HashLink>
                  </div>
                  <a href="https://opensea.io/collection/lionsnotsheep" target="_blank" className="btn btn-lg gradient-2" rel="noreferrer">
                    <img src={opensea_icon} alt="opensea-icon" /> VIEW ON OPENSEA
                  </a>
                </div>
              ) : (
                metas && (
                <div className="section-body lions-section">
                  <div className="row lions-row">
                  {metas.map((e,i) => {
                    return(
                      <div className="col-md-3 col-sm-6 text-center mt-2" key={i}>
                        <img src={e.image} alt="lions" />
                        <h3>{e.name}</h3>
                      </div>
                    )
                  })}
                  </div>
                  <div className="mt-5">
                    <img className="vault-border" alt="vault-border" src={border_image}/>
                  </div>
                </div>
              )
            )}
         
          { account && ( dashboardLoading ? (
              <div className="d-flex align-items-center justify-center mt-5">
                <ReactLoading
                  type="spin"
                  color="#c17717"
                  height={120}
                  width={120}
                />
              </div>
              ):(
                userInfo && userInfo!=null && (
                <div className="container dashboard mt-5">
                  <h1 className="gradient-text section-title text-center">YOUR LNS DASHBOARD</h1>
                  <Tabs>
                    <TabList className="text-center p-0 dashboard-menu mt-2">
                      <Tab className="btn gradient-btn">MY PROFILE</Tab>
                      <Tab className="btn gradient-btn">MY TEAM</Tab>
                      <Tab className="btn gradient-btn">MY NFTS</Tab>
                      <Tab className="btn gradient-btn">REFLECTIONS</Tab>
                    </TabList>
  
                    <TabPanel>
                      <div className="d-flex align-items-center justify-center">
                        <div className="dashboard-content">
                          <p>Full Name: {userInfo.mdetails.username}</p>
                          <p>Call Sign: {userInfo.mdetails.username}</p>
                          <p>Referred Call Sign: {userInfo.mdetails.parentusername}</p>
                          <p>MetaMaskID: {userInfo.mdetails.metamaskid}</p>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="d-flex align-items-center justify-center">
                        {
                          userInfo.squadron.length>0 ? (
                            <div className="dashboard-content">
                              {
                                userInfo.squadron.map((item,index) => {
                                  return(
                                    <p key={index}>Level {item.level} Enlistment : {item.squadron.length} with {item.squadron.reduce((a, b) => a + (b['nfts'] || 0), 0)} NFTs</p>
                                  );
                                })
                              }
                            </div>
                          ) : (
                            <div className="dashboard-content text-center">
                              NO TEAM
                            </div>
                          )
                        }
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="d-flex align-items-center justify-center">
                        <div className="dashboard-content">
                            <p>Total NFTs: {userInfo.mdetails.nfts}</p>
                            <p>MetaMaskID: {userInfo.mdetails.metamaskid}</p>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <Tabs>
                          <TabList className="p-0 dashboard-submenu">
                            <Tab className="btn gradient-btn">TOTAL</Tab>
                            <Tab className="btn gradient-btn">CLAIMED</Tab>
                            <Tab className="btn gradient-btn">CLAIMABLE</Tab>
                          </TabList>
                          <TabPanel>
                            <div className="dashboard-content" style={{display:"inline-block"}}>
                              <p>DIRECT REFLECTIONS : {directPayout} ETH</p>
                              <p>AFFILIATE REFLECTIONS : {affiliatePayout} ETH</p>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="dashboard-content" style={{display:"inline-block"}}>
                                <p>DIRECT REFLECTIONS : {directPaidAmount} ETH</p>
                                <p>AFFILIATE REFLECTIONS : {affiliatePaidAmount} ETH</p>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="dashboard-content" style={{display:"inline-block"}}>
                              <p>DIRECT REFLECTIONS : {(directPayout-directPaidAmount).toFixed(3)} ETH</p>
                              <p>AFFILIATE REFLECTIONS : {(affiliatePayout-affiliatePaidAmount).toFixed(3)} ETH</p>
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
                )
              ))
            }
          <div className="mt-5 text-center vote-area">
            <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            <div className="elfsight-app-3af702ed-df50-4f68-adaf-9a4d02c94d86"></div>
          </div>
      </div>
    </div>
  );
};
