import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { onUserSignUp, onReferrerIDVerify, onNewReferralIDVerify } from "../../redux/actions";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import { motion } from 'framer-motion/dist/framer-motion';
import authAxios from "../../redux/apis/axios";
import { validate } from "react-email-validator";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import discord_icon from '../../assets/images/verify/discord-icon.png';
import verify_bottom from '../../assets/images/verify/verify-bottom-b.png';

export const Verify = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;

  const affiliate = useSelector((state) => state.affiliate);
  //states
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    role: "",
    captcha: "",
    phone: ""
  });

  const [referrerID, setReferrerID] = useState("");
  const [newReferralID, setNewReferralID] = useState("");
  const [discordUserName, setDiscordUserName] = useState("");
  const [captchaToken, setCaptchaToken] = useState();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [disable, setDisable] = useState(true);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [discordLoading, setDiscordLoading] = useState(false);
  const [referrerIDLoading, setReferrerIDLoading] = useState(false);
  const [newReferralIDLoading, setNewReferralIDLoading] = useState(false);

  const [userPhoneLoading, setUserPhoneLoading] = useState(false);
  const [userEmailLoading, setUserEmailLoading] = useState(false);
  const [userPhoneCode, setUserPhoneCode] = useState("");
  const [userEmailCode, setUserEmailCode] = useState("");
  const [checkUserPhone, setCheckUserPhone] = useState(false);
  const [sentUserPhone, setSentUserPhone] = useState(false);
  const [checkUserEmail, setCheckUserEmail] = useState(false);
  const [sentUserEmail, setSentUserEmail] = useState(false);

  const dispatch = useDispatch();

  const handleConnectWallet = async () => {
    if (window.ethereum) {
      await window.ethereum.enable();
      setMetamaskConnnected(true);
    } else {
      window.open('https://metamask.io/', '_blank');
    }
  };

  useEffect(() => {
    if (account && captchaToken != null && newReferralID != "" && affiliate.referralID == newReferralID && affiliate.referralID != "None" && (affiliate.referrerID == referrerID || referrerID =="")) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    stopLoading();
  }, [captchaToken, affiliate, newReferralID, referrerID, userEmail]);

  useEffect(() => {
    if (affiliate.referrerID == null) {
      setReferrerID("");
      swal("Sorry!", "Please input correct referrerId", "error");
    }
  }, [affiliate.referrerID])

  useEffect(() => {
    if (affiliate.referralID && affiliate.referralID != "None") {
      // setReferrerID(affiliate.checkedDetail.parent_id);
      setNewReferralID(affiliate.referralID);
    }
    if (affiliate.errorMsg != "") {
      swal("Sorry!", affiliate.errorMsg, "error");
    }
    if (affiliate.referrerID == null)
      setReferrerID("");
    else
      setReferrerID(affiliate.referrerID);
  }, [affiliate])

  const stopLoading = () => {
    setSignUpLoading(false);
    setReferrerIDLoading(false);
    setNewReferralIDLoading(false);
    setDiscordLoading(false);
  };
  const verifyReferrerID = () => {
    if (referrerID == "") {
      swal("Warning!", "Please input your Referrer ID!", "warning");
    } else {
      setReferrerIDLoading(true);
      dispatch(
        onReferrerIDVerify({
          username: referrerID,
          account: account
        })
      );
    }
  };
  const verifyNewReferralID = () => {
    if (account) {
      if (newReferralID == "") {
        swal("Warning!", "Please input your Referral ID!", "warning");
      } else if (newReferralID.length < 4) {
        swal("Warning!", "Minimum 4 characters", "warning");
      } else {
        setNewReferralIDLoading(true);
        dispatch(
          onNewReferralIDVerify({
            username: newReferralID
          })
        );
      }
    } else {
      swal("Warning!", "Please connect wallet!", "warning");
    }
  };

  const verifyPhoneNumber = async () => {
    if(userPhone == "") {
      swal("Warning!", "Please input your phone number!", "warning");
    } else {
      setUserPhoneLoading(true);
      if(!account) {
        swal("Warning!", "Please connect your metamask wallet!", "warning");
      } else {
        try{
          const res = await authAxios.post("/verifyPhone", {address: account, userPhone: userPhone});
          if(res.data.success == false) {
            swal("Warning!", res.data.message, "warning");
            if(res.data.error) console.log(res.data.error);
            setUserPhoneLoading(false);
          }else{
            setSentUserPhone(true);
          }
        } catch(err) {
          console.log("error!!!", err);
        }
      }
    }
  };
  const verifyPhoneCode = async () => {
    if(userPhoneCode == "") {
      swal("Warning!", "Please input your verificiation code!", "warning");
    } else {
      setUserPhoneLoading(true);
      try{
        const res = await authAxios.post("/verifyPhoneCode", {address: account, userPhoneCode: userPhoneCode});
        if(res.data.success == false) {
          swal("Warning!", res.data.message, "warning");
          setUserPhoneLoading(false);
        }else{
          setCheckUserPhone(true);
          setUserPhoneLoading(false);
        }
      } catch(err) {
        console.log("error!!!", err);
        setUserPhoneLoading(false);
      }
    }
  }
  const verifyEmail = async () => {
    if(userEmail == "") {
      swal("Warning!", "Please input your Email!", "warning");
    } else if(validate(userEmail) == false) {
      swal("Warning!", "Please input the correct Email!", "warning");
    } else {
      setUserEmailLoading(true);
      if(!account) {
        swal("Warning!", "Please connect your metamask wallet!", "warning");
      } else {
        try{
          const res = await authAxios.post("/verifyEmail", {address: account, userEmail: userEmail});
          if(res.data.success == false) {
            swal("Warning!", res.data.message, "warning");
            /* if(res.data.error) console.log(res.data.error); */
            setUserEmailLoading(false);
          }else{
            setSentUserEmail(true);
          }
        } catch(err) {
          console.log("error!!!", err);
          setUserEmailLoading(false);
        }
      }
    }
  };
  const verifyEmailCode = async ()=> {
    if(userEmailCode == "") {
      swal("Warning!", "Please input your email verification code!", "warning");
    } else {
      setUserEmailLoading(true);
      try{
        const res = await authAxios.post("/verifyEmailCode", {address: account, userEmailCode: userEmailCode});
        if(res.data.success == false) {
          swal("Warning!", res.data.message, "warning");
          setUserEmailLoading(false);
        }else{
          setCheckUserEmail(true);
          setUserEmailLoading(false);
        }
      } catch(err) {
        console.log("error!!!", err);
        setUserEmailLoading(false);
      }
    }
  }

  const signUp = () => {
    if (!disable) {
      if (newReferralID == "") {
        swal("Warning!", "Please input your Referral ID!", "warning");
      } else {
        setSignUpLoading(true);
        dispatch(
          onUserSignUp({
            address: account,
            referrerId: affiliate.referrerID,
            referralId: affiliate.referralID,
            discordUserName: discordUserName,
            name: userName,
            email: userEmail,
            phone: userPhone,
            parentId: affiliate.parent_id
          })
        );

      }
    }
  };


  return (
    <div id="verify">
      <div className="container mt-5">
        <div className="step-item">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            viewport={{ once: true, amount: 0 }}
          >
            <div className="step-title"><h2>STEP 1</h2></div>
            <div className="step-content">
            <div className="">
                <h3 className="mt-5"><span style={{color: "#f20000"}}>1A</span> - CONNECT YOUR METAMASK WALLET.*</h3>
                <p>Connect your Metamask to participate in Lions Not Sheep Reflection.</p>
                <div>
                  {account ? (
                    <button
                      className="btn btn-lg gradient-btn connect-btn"
                    >
                      {account &&
                        `${account.substring(0, 6)}...${account.substring(
                          account.length - 4
                        )}`}
                    </button>
                  ) : (
                    <button
                      className="btn btn-lg gradient-btn connect-btn"
                      onClick={handleConnectWallet}
                    >
                      CONNECT YOUR METAMASK WALLET
                    </button>
                  )}
                </div>
              </div>
              <div className="mt-3">
                  <h3><span className="blue">1B</span> - VERIFY YOUR REFERRER CALL SIGN ID</h3>
                  <p>Enter the Referral Call Sign ID of the person that referred you.</p>
              </div>
              <div className="d-flex align-items-end verify-input">
                Enter The Referrer ID:
                <input
                  type="text"
                  className="w-100 input-info"
                  value={referrerID}
                  maxLength={16}
                  minLength={4}
                  placeholder=""
                  onChange={(e) => {
                    setReferrerID(e.target.value);
                  }}
                />
                {affiliate.referrerID != null && affiliate.referrerID != "" && affiliate.referrerID == referrerID ? (
                  <span className="tickIcon">&#10003;</span>
                ) : (
                  <>
                    {!referrerIDLoading ? (
                      <button className="btn btn-lg gradient-btn" onClick={verifyReferrerID}>Verify</button>
                    ) : (
                      <ReactLoading className="step-loading" type="spin" color="#c6872a" />
                    )}
                  </>
                )}
              </div>
            </div>
          </motion.div>
        </div>

        <div className="step-item">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            viewport={{ once: true, amount: 0 }}
          >
            <div className="step-title"><h2>STEP 2</h2></div>
            <div className="step-content">
              <div>
                  <h3>CREATE YOUR REFERRAL ID VERIFICATION</h3>
                  <p>
                    Create your Referral ID & Use the <strong>'Verify'</strong> button to complete this step. Share your referral ID if you would like to earn affiliate reflection payouts.
                  </p>
              </div>
              <div className="d-flex align-items-end verify-input">
                Create Your Referral ID:
                <input
                  type="text"
                  className="w-100 input-info"
                  value={newReferralID}
                  maxLength={16}
                  minLength={4}
                  placeholder=""
                  onChange={(e) => {
                    setNewReferralID(e.target.value);
                  }}
                />

                {newReferralID != "" && affiliate.referralID == newReferralID && affiliate.referralID != "None" ? (
                  <span className="tickIcon">&#10003;</span>
                ) : (
                  <>
                    {!newReferralIDLoading ? (
                      <button className="btn btn-lg gradient-btn" onClick={verifyNewReferralID}>Verify</button>
                    ) : (
                      <ReactLoading className="step-loading" type="spin" color="#c6872a" />
                    )}
                  </>
                )}
              </div>
            </div>
          </motion.div>
        </div>

        <div className="step-item">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            viewport={{ once: true, amount: 0 }}
          >
            <div className="step-title">
              <h2>STEP 3</h2>
            </div>
            <div className="step-content">
              <div>
                <h3><span style={{color: "#f20000"}}>3-1 </span>CLICK THE "FOLLOW"BUTTON BELOW TO FOLLOW OUR TWITTER ACCOUNT. </h3>
              </div>
              <div className="mt-2">
                  <a href="https://twitter.com/lionsnotsheep_" target="_blank" className="url border">https://twitter.com/lionsnotsheep_</a>
              </div>
              <div>
                <h3><span className="blue">3 - 2 </span>Join our Discord channel.</h3>
                <p>Become a community member for platform updates, giveaways and much more!<br />Find your Discord ID through account options (on PC) or use the profile button (on mobile). Your ID must include the # and numbers included.</p>
                <a href="https://discord.com/invite/lionsnotsheep" target="_blank" className="url">https://discord.com/invite/lionsnotsheep</a>
              </div>
              <p className="mt-5"><img src={discord_icon} alt="discord-btn"/> Type your discord ID to Verify</p>
              <div className="d-flex">
              <div className="flex-5 d-flex mt-5 align-items-end verify-input">
                Your Discord ID:
                <input
                  type="text"
                  className="w-100 input-info"
                  value={discordUserName}
                  placeholder="ex: Tom#5019"
                  onChange={(e) => {
                    setDiscordUserName(e.target.value);
                  }}
                ></input>
              </div>
              <div className="flex-1"></div>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="step-item">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            viewport={{ once: true, amount: 0 }}
          >
            <div className="step-title">
              <h2>STEP 4</h2>
            </div>
            <div className="step-content" id="two_fa">
              <div>
                  <h3>EMAIL ADDRESS FOR NOTIFICATIONS OR NEWSLETTERS, ETC.</h3>
                  <p className="mt-2">Stay up to date with all the latest LNS News and Announcements</p>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-5 mt-5 align-items-end">
                  Your Name :
                  <input
                    type="text"
                    className="w-100 input-info"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="flex-1"></div>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-5 mt-5 align-items-end">
                  Your Email :
                  <input
                    type="email"
                    className="w-100 input-info"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                  ></input>
                  {/* {checkUserEmail == true ? (
                    <span className="tickIcon">&#10003;</span>
                    ) : (
                    <>
                      {!userEmailLoading ? (
                        <button className="btn btn-lg gradient-btn" onClick={verifyEmail}>Verify</button>
                      ) : (
                        !sentUserEmail ? (
                        <ReactLoading className="step-loading" type="spin" color="#c6872a" />
                        ) : (
                          <>
                          <input
                            className="code-input"
                            values={userEmailCode}
                            placeholder="Enter the code we sent"
                            onChange={(e) => {
                              setUserEmailCode(e.target.value);
                          }}/>
                          <button className="btn btn-sm gradient-btn mr-1" onClick={verifyEmailCode}>Enter</button>
                          <button className="btn btn-sm gradient-btn" onClick={()=> {setUserEmailLoading(false)}}>Cancel</button>
                          </>
                        )
                      )}
                    </>
                  )} */}
                </div>
                <div className="flex-1"></div>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-5 mt-5 align-items-end">
                  Your Phone:
                  <PhoneInput
                    type="tel"
                    className="w-100 input-info"
                    value={userPhone}
                    onChange={setUserPhone}
                  />
                  {checkUserPhone == true ? (
                    <span className="tickIcon">&#10003;</span>
                    ) : (
                    <>
                      {!userPhoneLoading ? (
                        <button className="btn btn-lg gradient-btn" onClick={verifyPhoneNumber}>Verify</button>
                      ) : (
                        !sentUserPhone ? (
                          <ReactLoading className="step-loading" type="spin" color="#c6872a" />
                        ) : (
                          <>
                            <input
                              className="code-input"
                              values={userPhoneCode}
                              placeholder="Enter the code we sent"
                              onChange={(e) => {
                                setUserPhoneCode(e.target.value);
                            }}/>
                            <button className="btn btn-sm gradient-btn mr-1" onClick={verifyPhoneCode}>Enter</button>
                            <button className="btn btn-sm gradient-btn" onClick={()=> {setUserPhoneLoading(false)}}>Cancel</button>
                          </>
                        )
                      )}
                    </>
                  )}
                </div>
                <div className="flex-1"></div>
              </div>
              <p className="mt-2" style={{fontSize:"small"}}>*By signing up via text, you agree to receive recurring automated marketing text messages at the cell phone used to sign up. Consent is not a condition of purchase. Msg and data rates may apply. View terms and conditions.</p>
            </div>
          </motion.div>
        </div>

        <div className="step-item row">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            viewport={{ once: true, amount: 0 }}
          >
            <div className="step-title">
              <h2>STEP 5</h2>
            </div>
            <div className="step-content">
              <div>
                <h3>GOOGLE RECAPTCHA VERIFICATION.</h3>
              </div>
              <div className="mt-2 d-flex">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITEKEY}
                  onChange={(token) => {
                    setCaptchaToken(token);
                    let copyErrors = { ...errors };
                    if (token) {
                      copyErrors.captcha = undefined;
                    } else {
                      copyErrors.captcha = "";
                    }
                    setErrors(copyErrors);
                  }}
                  onErrored={(e) => {
                    let copyErrors = { ...errors };
                    copyErrors.captcha = "";
                    setErrors(copyErrors);
                  }}
                />
              </div>
            </div>
          </motion.div>
        </div>

        <div className="sign_up text-center mt-5">
          {!signUpLoading ? (
            <a
              className="btn btn-verify gradient-btn"
              onClick={signUp}
              disabled={disable}
            >
              Sign up
            </a>
          ) : (
            <div className="d-flex align-items-center justify-center text-center">
              <ReactLoading type="spin" color="#c6872a" />
            </div>
          )}
        </div>
      </div>
      <img className="bottom-bg" src={verify_bottom} alt="verify-bottom"/>
    </div>
  );
};
