import { combineReducers } from "redux";
import Mint from "./Mint";
import Auth from "./Auth";
import Affiliate from "./Affiliate";
import Rewards from "./Rewards";
import Vault from "./Vault";

const reducers = combineReducers({
  auth: Auth,
  mint: Mint,
  affiliate: Affiliate,
  rewards: Rewards,
  vault: Vault,
});
export default reducers;
