import { REFERRERID_CHECK_SUCCESS, REFERRERID_CHECK_FAILED, REFERRALID_CHECK_SUCCESS, REFERRALID_CHECK_FAILED, AFFILIATE_CHECK_SUCCESS, AFFILIATE_CHECK_FAILED } from '../shared/ActionTypes';

const INIT_STATE = {
    referrerID: "",
    referralID: "",
    parent_id: 1,
    errorMsg: "",
    verifyFlag: true,
};

const affiliateReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case REFERRERID_CHECK_SUCCESS: {
        return {
          ...state,
          referrerID: action.payload.referrerID,
          parent_id: action.payload.parent_id,
          errorMsg: ""
        };
      }
      case REFERRERID_CHECK_FAILED: {
        return {
          ...state,
          referrerID: "",
          parent_id: 1,
          errorMsg: action.payload.errorMsg,
        };
      }

      case REFERRALID_CHECK_SUCCESS: {
        return {
          ...state,
          referralID: action.payload.referralID,
          errorMsg: ""
        }
      }
      case REFERRALID_CHECK_FAILED: {
        return {
          ...state,
          errorMsg: action.payload.errorMsg,
        }
      }
      case AFFILIATE_CHECK_SUCCESS: {
        return {
          ...state,
          referralID: action.payload.referralID,
          referrerID: action.payload.referrerID,
          parent_id: action.payload.parent_id,
          verifyFlag: false
        }
      }
      case AFFILIATE_CHECK_FAILED: {
        return {
          ...state,
          referralID: "",
          referrerID: "",
          parent_id: 1,
          verifyFlag: true
        }
      }
      /* case UPDATE_REFERRERID_FAILED: {
        return {
          ...state,
          referrerID: "",
          parent_id: 1,
          errorMsg: action.payload.errorMsg,
        };
      } */
      default:
        return state;
    }
  };
  export default affiliateReducer;