import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Animated } from "react-animated-css";

import {
  onCheckUser,
  onCheckUserAffiliate,
  onCheckMintable,
  onGetTotalToken,
} from "../redux/actions";

import logo from "../assets/images/logo.png";
import button_icon from "../assets/images/button-icon.png";
import instagramIcon from "../assets/images/btn-instagram.png";
import discordIcon from "../assets/images/btn-discord.png";
import openseaIcon from "../assets/images/btn-opensea.png";
import facebookIcon from "../assets/images/btn-facebook.png";
import red_star from "../assets/images/star-red.png";
import blue_star from "../assets/images/star-blue.png";

export const Navbar = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected, mintpageFlag } = props;

  const dispatch = useDispatch();
  const verify = useSelector((state) => state.auth);
  const affiliate = useSelector((state) => state.affiliate);

  useEffect(() => {
   dispatch(onGetTotalToken());
  }, []);

   useEffect(() => {
     if (account) {
       /* dispatch(
         onCheckUser({
           address: account,
         })
       ); */
       /* dispatch(
         onCheckMintable({
           address: account,
         })
       ); */
       dispatch(
         onCheckUserAffiliate({
           metamask_id: account,
         })
       );
     }
   }, [account, verify.signup_success]);

  const handleConnectWallet = async () => {
    if (window.ethereum) {
      await window.ethereum.enable();
      setMetamaskConnnected(true);
      window.location.reload();
    } else {
      window.open("https://metamask.io/", "_blank");
    }
  };
  return (
    <>
      <div className="sub-nav">
        <h4>
          1% OF MINT REVENUE WILL GO DIRECTLY TO MILITARY VETERAN PROGRAMS
        </h4>
      </div>
      <nav className="navbar navbar-inverse">
        <Animated
          animationIn="fadeInLeft"
          animationOut="fadeOut"
          animationInDelay={200}
        >
          <div className="navbar-header">
            <button
              type="button"
              data-target="#navbarCollapse"
              data-toggle="collapse"
              className="navbar-toggle"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link to="/" rel="nofollow" className="navbar-brand">
              <img src={logo} className="logo" alt="logo"></img>
            </Link>
          </div>
        </Animated>
        <Animated
          className="navbar-div"
          animationIn="fadeInRight"
          animationOut="fadeOut"
          animationInDelay={500}
        >
          <div id="navbarCollapse" className="collapse navbar-collapse">
            <ul className="nav navbar-nav navbar-right hidden-xs">
              <li>
                {metamaskConnected ? (
                  <>
                    <button className="btn btn-sm gradient-btn">
                      {affiliate.referralID}
                      <img src={button_icon} />
                      {account && (
                        <>
                          {account.substring(0, 5)}...
                          {account.substring(account.length - 4)}
                        </>
                      )}
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-sm gradient-btn"
                    onClick={handleConnectWallet}
                  >
                    CONNECT WALLET
                    <img src={button_icon} />
                  </button>
                )}
              </li>
              <li>
                <a
                  className="social-btn"
                  href="https://www.instagram.com/lionsnotsheepnft/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagramIcon}
                    className="social-icons purple"
                    alt="instagramIcon"
                  />
                </a>
              </li>
              <li>
                <a
                  className="social-btn"
                  href="https://discord.com/invite/lionsnotsheep"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={discordIcon}
                    className="social-icons purple"
                    alt="discordIcon"
                  />
                </a>
              </li>
              <li>
                <a
                  className="social-btn"
                  href="https://opensea.io/collection/lionsnotsheep"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={openseaIcon}
                    className="social-icons purple"
                    alt="socialIcon"
                  />
                </a>
              </li>
              <li>
                <a
                  className="social-btn"
                  href="https://www.facebook.com/LionsNotSheep/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebookIcon}
                    className="social-icons purple"
                    alt="socialIcon"
                  />
                </a>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/#purpose" rel="nofollow">
                  WHO WE ARE
                </a>
              </li>
              <li className="hidden-xs">
                <a href="" rel="nofollow">
                  <img src={blue_star} alt="star" />
                </a>
              </li>
              <li>
                <a href="/#utilities" rel="nofollow">
                  UTILITY
                </a>
              </li>
              <li className="hidden-xs">
                <HashLink smooth to="">
                  <img src={red_star} alt="star" />
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#roadmap">
                  ROADMAP
                </HashLink>
              </li>
              <li className="hidden-xs">
                <Link to="/" rel="nofollow">
                  <img src={blue_star} alt="star" />
                </Link>
              </li>
              <li>
                <Link to="/claim" rel="nofollow">
                  CLAIM
                </Link>
              </li>
              {/* <li>
                <a href="/#collections" rel="nofollow">
                  NFT COLLECTIONS
                </a>
              </li> */}
              <li className="hidden-xs">
                <a rel="nofollow">
                  <img src={red_star} alt="star" />
                </a>
              </li>
              <li>
                <Link to="/vault" rel="nofollow">
                  VAULT
                </Link>
              </li>
              <li></li>
              <li className="hidden-xs">
                <a rel="nofollow">
                  <img src={blue_star} alt="star" />
                </a>
              </li>
              {/* <li>
                <Link to="/rewards" rel="nofollow">
                  REWARDS
                </Link>
              </li> */}
              <li className="hidden-xs">
                <a rel="nofollow">
                  <img src={red_star} alt="star" />
                </a>
              </li>
              <li>
                <a href="/#faqs" rel="nofollow">
                  FAQ'S
                </a>
              </li>
              <li className="hidden-xs">
                <Link to="" rel="nofollow">
                  <img src={blue_star} alt="star" />
                </Link>
              </li>
              <li>
                <Link to="/getVerified" rel="nofollow" style={{ paddingRight: 0 }}>
                  GET VERIFIED
                </Link>
              </li>
              <li className="hidden-xs">
                <a rel="nofollow">
                  <img src={red_star} alt="star" />
                </a>
              </li>
              <li className="visible-xs">
                {metamaskConnected ? (
                  <a>
                    <img src={button_icon} /> {affiliate.referralID} : 
                    {account &&
                      ` ${account.substring(0, 5)}...${account.substring(
                        account.length - 4
                      )}`}
                  </a>
                ) : (
                  <a onClick={handleConnectWallet}>CONNECT WALLET</a>
                )}
              </li>
            </ul>
          </div>
        </Animated>
      </nav>
    </>
  );
};
