import {
    CHECK_REWARDS_FAILED,
    CHECK_REWARDS_SUCCESS,
    GET_CLAIMDATA_FAILED,
    GET_CLAIMDATA_SUCCESS,
} from "../shared/ActionTypes.js";

const INIT_STATE = {
    failedMsg: null,
    rewardAmount: 0,
    paidAmount: 0,
    directAmount: 0,
    directPaidAmount: 0,
    affiliateAmount: 0,
    affiliatePaidAmount: 0,
    claimData: [],
};

const rewardsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHECK_REWARDS_FAILED: {
            return {
                ...state,
                failedMsg: action.payload.failedMsg,
                rewardAmount: action.payload.rewardAmount?action.payload.rewardAmount:0,
                paidAmount: action.payload.paidAmount?action.payload.paidAmount:0,
                directAmount: action.payload.directAmount?action.payload.directAmount:0,
                directPaidAmount: action.payload.directPaidAmount?action.payload.directPaidAmount:0,
                affiliateAmount: action.payload.affiliateAmount?action.payload.affiliateAmount:0,
                affiliatePaidAmount: action.payload.affiliatePaidAmount?action.payload.affiliatePaidAmount:0,
            };
        }
        case CHECK_REWARDS_SUCCESS: {
            return {
                ...state,
                rewardAmount: action.payload.rewardAmount,
                paidAmount: action.payload.paidAmount,
                directAmount: action.payload.directAmount?action.payload.directAmount:0,
                directPaidAmount: action.payload.directPaidAmount?action.payload.directPaidAmount:0,
                affiliateAmount: action.payload.affiliateAmount?action.payload.affiliateAmount:0,
                affiliatePaidAmount: action.payload.affiliatePaidAmount?action.payload.affiliatePaidAmount:0,
            };
        }
        case GET_CLAIMDATA_SUCCESS: {
            return {
                ...state,
                claimData: action.payload.claimData,
            }
        }
        case GET_CLAIMDATA_FAILED: {
            return {
                ...state,
                claimData: [],
            }
        }
        default:
            return state;
    }
};
export default rewardsReducer;