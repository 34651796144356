import {
    METADATA_CHECK_SUCCESS,
    METADATA_CHECK_FAILED,
    USERINFO_GET_SUCCESS,
    USERINFO_GET_FAILED
} from "../shared/ActionTypes.js";
import authAxios from "../apis/axios";
import swal from 'sweetalert';

export const onGetMetadata = ({
    address
}) => {
    return async (dispatch) => {
        const body = {
            address
        };
        try {
            const res = await authAxios.post("/getMetadata", body);
            if (res.data.success == false) {
                dispatch({
                    type: METADATA_CHECK_FAILED,
                });
            } else {
                dispatch({
                    type: METADATA_CHECK_SUCCESS,
                    payload: {
                        metadata: res.data.metas
                    },
                });
            }
        } catch (err) {
            console.log("error!!!!", err);
            swal("Sorry!", "Network Error", "error");
                dispatch({
                    type: METADATA_CHECK_FAILED,
                });
        }
    };
};

export const onGetUserInfo=({
    address
}) => {
    return async (dispatch) => {
        const body = {
            address
        };
        try {
            const res = await authAxios.post("/getUserInfo", body);
            if (res.data.success == false) {
                dispatch({
                    type: USERINFO_GET_FAILED,
                    payload: {
                        message: res.data.message
                    },
                });
            } else {
                dispatch({
                    type: USERINFO_GET_SUCCESS,
                    payload: {
                        userInfo: res.data.userInfo
                    },
                });
            }
        } catch (err) {
            console.log("error!!!!", err);
            swal("Sorry!", "Network Error", "error");
            dispatch({
                type: USERINFO_GET_FAILED,
            });
        }
    };
}