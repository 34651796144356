//import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Animated } from "react-animated-css";

import header_lions from "../../assets/images/header_lions.png";
import button_icon from "../../assets/images/button-icon.png";
import "./claim.css";
export const Header = () => {
  const mintable = useSelector((state) => state.mint);

  return (
    <Animated
      animationIn="fadeInDown"
      animationOut="fadeOut"
      animationInDelay={0}
    >
      <header id="header">
        <div className="intro bg-block">
          <div className="section-body">
            <div className="gradient-overlay bg-block"></div>
            <div className="header-content text-center">
              <div>
                <Animated
                  animationIn="zoomIn"
                  animationOut="fadeOut"
                  animationInDelay={1000}
                >
                  {/* <img className="header_logo" src={logo_header} alt="logo_header"></img> */}
                  <h1 className="main-title">CLAIM REWARDS</h1>
                  <h3>
                    FOLLOW THE SIMPLE STEPS BELOW TO<br />CLAIM YOUR LIONS NOT SHEEP REWARDS
                  </h3>
                </Animated>
              </div>
              <Animated
                animationIn="flipInY"
                animationOut="fadeOut"
                animationInDelay={1500}
              >
                <a className="btn btn-lg gradient-btn soldout" href="#steps">
                  <img src={button_icon}></img>CLAIM REWARDS
                </a>
              </Animated>
            </div>
            <Animated
              animationIn="bounceInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDelay={1300}
            >
              <div>
                <img width="100%" src={header_lions} alt="header_lions"></img>
              </div>
            </Animated>
          </div>
        </div>
      </header>
    </Animated>
  );
};
