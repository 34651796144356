import {
  BALANCE_CHECK_SUCCESS,
  REFERRERID_CHECK_SUCCESS,
  REFERRALID_CHECK_SUCCESS,
  DISCORD_CHECK_SUCCESS,
  SIGNUP_SUCCESS,
  USER_CHECK_SUCCESS,
} from "../shared/ActionTypes.js";

const INIT_STATE = {
  checkBalance: null,
  // checkTwitter: null,
  checkDiscord: true,
  checkReferrerID: null,
  checkReferralID: null,
  signup_success: null,
  user_check: null,
  // loading: true,
  // user: '',
  // token: null
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BALANCE_CHECK_SUCCESS: {
      return {
        ...state,
        checkBalance: action.payload.checkBalance,
      };
    } 
    case REFERRERID_CHECK_SUCCESS: {
      return {
        ...state,
        checkReferrerID: action.payload.checkReferrerID,
      };
    }
    case REFERRALID_CHECK_SUCCESS: {
      return {
        ...state,
        checkReferralID: action.payload.checkReferralID
      }
    }
    case DISCORD_CHECK_SUCCESS: {
      return {
        ...state,
        checkDiscord: action.payload.checkDiscord,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        signup_success: action.payload.signup_success
      }
    }
    case USER_CHECK_SUCCESS: {
      return {
        ...state,
        user_check: action.payload.checkUser
      }
    }
    default:
      return state;
  }
};
export default authReducer;
