import sub_logo from "../../assets/images/sub-logo.png";
import founder1 from "../../assets/images/founder/founder1.jpg";
import founder2 from "../../assets/images/founder/founder2.jpg";
import founder3 from "../../assets/images/founder/founder3.png";
import opensea_icon from "../../assets/images/opensea_icon.png";

import {motion} from 'framer-motion/dist/framer-motion';

export const Founders = (props) => {
  return (
    <>
    <div className="founders text-center">
      <div className="founder1-section pt-5">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, type: "spring", stiffness: 100 }}
        viewport={{ once: true, amount: 0 }}
      >
      <img className="img-bg" src={sub_logo} alt="fonder_logo"></img>
      <h1 className="section-title gradient-text">MEET OUR FOUNDERS</h1>
      <div className="section-body">
        <div className="row text-center">
          <div className="col-md-3 col-sm-4">
          <img className="img-responsive img-circle" src={founder2} />
            <div>
              <h3 className="text-white font-headline">GREG ANDERSON</h3>
              <p>Founder</p>
              {/* <div className="founder-des">
                <a><i className="fab fa-discord"></i></a>
                <a><img src={opensea_icon} alt="opensea-icon" /></a>
              </div> */}
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <img className="img-responsive img-circle" src={founder1} />
            <div>
              <h3 className="text-white font-headline">SEAN WHALEN</h3>
              <p>Founder</p>
              {/* <div className="founder-des">
                <a><i className="fab fa-discord"></i></a>
                <a><img src={opensea_icon} alt="opensea-icon" /></a>
              </div> */}
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <img className="img-responsive" src={founder3} />
            <div>
              <h3 className="text-white font-headline">META LABS AGENCY</h3>
              <p>Design & Web3 Development</p>
              {/* <div className="founder-des">
                <a><i className="fab fa-discord"></i></a>
                <a><img src={opensea_icon} alt="opensea-icon" /></a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      </motion.div>
      </div>
    </div>
    <div className="founders-bottom bg-block">
      <div className="container text-center">
        <img className="img-bg" src={sub_logo} alt="fonder_logo"></img>
        <h1 className="section-title gradient-text">SPECIAL THANKS TO OUR 30+ TEAM MEMBERS BEHIND THE LNS ECOSYSTEM</h1>
        <h1 className="section-title">WE SALUTE YOU!</h1>
      </div>
      
    </div>
    <div className="disclaimer mt-5 mb-0">
        <div className="container">
          <h1 className="section-title text-center">DISCLAIMER</h1>
          <p className="mt-2"><strong>General.</strong> This document describes LNS Digital and Non Fungible Token community and the current vision for the architecture of the community post mint. The sole financial transaction between the purchaser and Community is limited to the initial mint and purchase of the Non Fungible Token (NFT) on the ERC 721 blockchain. There is no other financial contract or implied relationship whether financial or other between the purchaser and the community or the community founders.</p>
          <p>The contribution or any use of proceeds is at the sole election of the founders and their choice in use of proceeds from the sale of the NFT collection. Any potential benefit or how it is distributed is also at the sole discretion of the founders. There is no representation of any amount as it relates to the founders choice in use of proceeds from the sale.</p>
          <p><strong>Disclaimer of Liability.</strong> LNS Digital (LNSD) does not make or purport to make, and hereby disclaims, any representation, warranty, or guarantee of any type or nature whatsoever (including those that are implied) to any person or entity, including, without limitation, any representation, warranty, or guarantee arising out of or relating to the truth, accuracy, and precision of any information in this document and the LNSD ecosystem, or LNSD future platform. To the maximum extent permitted by applicable law, regulations, and rules, LNSD will not be liable for any indirect, special, incidental, consequential, or other losses of any kind, in tort, contract, or otherwise (including, without limitation, loss of revenue, income, or profits, and loss of use or data) arising out of or relating to any acceptance of or reliance on this document or any part thereof by you. </p>
          <p>This document does not obligate any party to enter into any contract or binding legal commitment or to accept any form of payment for any purpose. Any agreement with LNSD for the purchase of an NFT from the collection will be governed by the terms of use and privacy policy of that agreement, which will prevail over this document.</p>
          <p><strong>Not A Securities Offering.</strong> The LNSD NFTs are a stand alone collection and the purchase of such is an independent transaction and has no relationship to the choice in use of proceeds by the community founders as it relates to their choice in supporting the vision and roadmap of the LNSD Community. This document or any part of it does not constitute a prospectus or offer document of any type or nature and is not intended to constitute an offer of securities or a solicitation for investment insecurities in any jurisdiction.</p>
          <p><strong>Risks and Uncertainties.</strong> Prospective purchasers of LNSD NFT should carefully consider and evaluate all risks and uncertainties associated with the digital NFT market, LNSD, the LNSD ecosystem, LNSD Platform as the digital asset market is a volatile and risky market. These risks and uncertainties may include, without limitation, the following: risks relating to the value of the LNSD NFT risk relating to competitive conditions; risks relating to blockchain technology and software, including delays, interruptions, errors, losses, advances, and changes; security risks to your access and use of a digital wallet and blockchain applications; risk relating to platform development and implementation of business strategies, including, reliance on its personnel and third-party service providers; political or government risk, including changes to applicable laws, regulations, and rules, and enforcement actions. If any such risks and uncertainties arises, such events may materially and adversely affect LNSD, and you may lose all or part of the value of the LNSD NFTs</p>
          <p><strong>Non-advisory.</strong> This document does not constitute any legal, tax, regulatory, financial, accounting, or other advice, and is not intended to provide the sole basis for any evaluation of LNSD, LNSD NFT the LNSD ecosystem, or the LNSD community. Before acquiring LNSD NFTs a prospective purchaser should consult legal, investment, tax, accounting, and other advisors to determine the potential benefits, burdens, and other consequences of such a transaction.</p>
        </div>
      </div>
    </>
  );
};
