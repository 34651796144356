import {
    CHECK_REWARDS_SUCCESS,
    CHECK_REWARDS_FAILED,
    GET_CLAIMDATA_SUCCESS,
    GET_CLAIMDATA_FAILED,
} from "../shared/ActionTypes.js";
import authAxios from "../apis/axios";
import swal from 'sweetalert';

export const onCheckRewards = ({
    address
}) => {
    return async (dispatch) => {
        const body = {
            address
        };
        try {
            const res = await authAxios.post("/checkRewards", body);
            if (res.data.success == false) {
                dispatch({
                    type: CHECK_REWARDS_FAILED,
                    payload: {
                        failedMsg: res.data.message,
                        rewardAmount: res.data.rewardAmount,
                        paidAmount: res.data.paidAmount,
                        directAmount: res.data.directAmount,
                        directPaidAmount: res.data.directPaidAmount,
                        affiliateAmount: res.data.affiliateAmount,
                        affiliatePaidAmount: res.data.affiliatePaidAmount,
                    },
                });
            } else {
                dispatch({
                    type: CHECK_REWARDS_SUCCESS,
                    payload: {
                        rewardAmount: res.data.rewardAmount,
                        paidAmount: res.data.paidAmount,
                        directAmount: res.data.directAmount,
                        directPaidAmount: res.data.directPaidAmount,
                        affiliateAmount: res.data.affiliateAmount,
                        affiliatePaidAmount: res.data.affiliatePaidAmount,
                        failedMsg: res.data.message,
                    },
                });
            }
        } catch (err) {
            // swal("Sorry!", "Network Error", "error");
            dispatch({
                type: CHECK_REWARDS_FAILED,
                payload: {
                    failedMsg: "Network Error",
                },
            });
            console.log("error!!!!", err);
        }
    };
};

export const onGetClaimData = ({
    address
}) => {
    return async (dispatch) => {
        const body = {
            address
        };
        try {
            const res = await authAxios.post("/getClaimData", body);
            if (res.data.success == false) {
                swal("Sorry!", res.data.message, "error");
                dispatch({
                    type: GET_CLAIMDATA_FAILED,
                });
            } else {
                dispatch({
                    type: GET_CLAIMDATA_SUCCESS,
                    payload: {
                        claimData: res.data
                    },
                });
            }
        } catch (err) {
            console.log("error!!!!", err);
            swal("Sorry!", "Network Error", "error");
                dispatch({
                    type: GET_CLAIMDATA_FAILED,
                });
        }
    };
};

export const setClaimHistory = ({
    address, historyId,
}) => {
    return async (dispatch) => {
        const body = {
            address, historyId,
        };
        try {
            const res = await authAxios.post("/setClaimHistory", body);
            //console.log('success: ', res.data.success);
        } catch (err) {
            console.log("error!!!!", err);
            swal("Sorry!", "Network Error", "error");
                dispatch({
                    type: GET_CLAIMDATA_FAILED,
                });
        }
    };
};

