import { motion } from "framer-motion/dist/framer-motion";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import button_icon from "../../assets/images/button-icon.png";
import sub_logo from "../../assets/images/sub-logo.png";
import who_people from "../../assets/images/who-people.png";
import cross_logo from "../../assets/images/mint/cross-logo.png";

import BountySlider from "../bounty_slider";

export const Who = (props) => {
  const mintable = useSelector((state) => state.mint);
  return (
    <div id="purpose" className="purpose text-center">
      <div className="purpose-body1 bg-block">
        <BountySlider></BountySlider>
        <div id="story" className="container">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.6 }}
          >
            <img src={sub_logo} className="" alt="purpose_logo"></img>
            <h1 className="section-title gradient-text">WE ARE LIONS NOT SHEEP</h1>
            <h2>
              <span style={{ color: "#fc0000" }}>
                YOU HAVE TWO CHOICES. TO LEAD OR
              </span>
              <span style={{ color: "#0061fa" }}> TO BE LED.</span>
            </h2>
            
            <div className="mt-3">
             <p>
              Lions Not Sheep NFT is your key to community built on a foundation of getting your house in order and aligning with those that feel the same. This NFT is your access to one of the largest communities in the world, Lions Not Sheep. We teach people about physicality and body, wealth management, creation and marketing, meditation and clear consciousness, clear programmings and even relationships. 
             </p>
            </div>
            {/* <a className="btn btn-lg border-btn mint-btn" target="_blank">
              MINT COMING SOON
            </a> */}
            {
              !mintable.soldout ? (
                <>
                  {/* <HashLink smooth to="/mint#" className="btn btn-lg border-btn mint-btn">
                    MINT YOUR LIONS NOT SHEEP NFT 
                    <span className="font-poppins"> (</span>available :  {parseInt(process.env.REACT_APP_TOTAL_TOKEN) - mintable.totalToken}<span className="font-poppins">)</span>
                  </HashLink> */}
                  <br />
                  {/* <HashLink smooth to="/mintCross#" className="btn btn-lg border-btn mint-btn credit-btn">
                    <img src={cross_logo} alt="cross logo" style={{width: "25px", marginTop: "-7px"}}/>
                    MINT WITH CREDIT/DEBIT CARD
                  </HashLink> */}
                </>
              ) : (
                <a className="btn btn-lg border-btn mint-btn" target="_blank">
                  <img src={button_icon}></img>  1,776 SOLD OUT
                </a>
              )
            }
          </motion.div>
        </div>
        <div className="container-fluid text-left mt-5">
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
            viewport={{ once: true, amount: 0 }}
          >
            <div className="row">
              <div className="col-sm-6 text-right">
                <img src={who_people} alt="purpose hunter" />
              </div>
              <div className="col-sm-6 purpose-description">
                <h1 className="section-title gradient-text">WE STAND FOR</h1>
                <h2>
                  <span style={{ color: "#fc0000" }}>
                    GETTING YOUR HOME
                  </span>
                  <span style={{ color: "#0061fa" }}> IN ORDER</span>
                </h2>
                <div className="description mt-5">
                  <p>Stand up, step out and take responsibility for what is yours and the cards you have been dealt. We align with alpha values and give you the tools to get your house in order. Any time you are out of balance in one  way you are out of balance in all and it is our mission to help lead the way into your control.</p>
                  <p className="mt-5">This is a global community and with our step into Web3  our Lions Not Sheep NFTs will help us spread our mission farther and wider than ever before.</p>
                </div>
                <a className="btn btn-lg gradient-btn mint-btn" href="https://discord.com/invite/lionsnotsheep" target="_blank">
                  <i className="fab fa-discord"></i> JOIN OUR DISCORD
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
