import Slider from "react-slick";

const BountySlider =() => {
  const imageUrlArray = [];
  
  for (let i = 1; i <= 8; i++) {
    imageUrlArray.push(
      <div className={"slider-item item-" + i} key={i}>
        <img 
          className="slider-item-bg"
          src={"../img/image/slider_bg_" + i + ".png"}
        ></img>
        <img
          className="slider-item-img"
          src={"../img/image/slider_hunter_" + i + ".png"}
        ></img>
        <div className="hidden">
          <h3 className="text-white font-stimul2">HUNTER NAME</h3>
          <h4 className="font-dinBold">UNIQUE ID 123345</h4>
        </div>
      </div>
    );
  }
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    touchMove: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="">
      <div className=" text-center" style={{ overflow: "hidden" }}>
        <div className="row slider-content" style={{ flexDirection: "column" }}>
          <Slider {...settings}>{imageUrlArray}</Slider>
        </div>
      </div>
    </div>
  );
}

export default BountySlider;