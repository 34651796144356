import { METADATA_CHECK_SUCCESS, METADATA_CHECK_FAILED, USERINFO_GET_SUCCESS, USERINFO_GET_FAILED} from '../shared/ActionTypes';

const INIT_STATE = {
    metadata: [],
    userInfo: null,
    errorMsg: null,
    userInfoMsg: null,
};

const vaultReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case METADATA_CHECK_SUCCESS: {
        return {
          ...state,
          metadata: action.payload.metadata,
          errorMsg: ""
        };
      }
      case METADATA_CHECK_FAILED: {
        return {
          ...state,
          metadata: [],
          errorMsg: "Haven't joined our empire yet? Get your Lions Not Sheep today."
        };
      }
      case USERINFO_GET_SUCCESS: {
        return {
          ...state,
          userInfo: action.payload.userInfo,
        };
      }
      case USERINFO_GET_FAILED: {
        return {
          ...state,
          userInfo: null,
          userInfoMsg: action.payload.message
        };
      }
      default:
        return state;
    }
  };
  export default vaultReducer;