import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import 'animate.css';
import { Animated } from "react-animated-css";

import Loading from "./pages/loading";
import Main from "./pages/main";
import Reflections from "./pages/reflectionspool";
import Signup from "./pages/signup";
import Auction from "./pages/auction";
import AuctionSub from "./pages/auction_sub";
import Mint from "./pages/mint";
import MintCross from "./pages/mint_cross";
import Vault from "./pages/vault";
import Claim from "./pages/claim";
import Policy from "./pages/policy";
import Terms from "./pages/terms";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import "./App.css";

import Web3 from "web3";

const App = () => {
  const [metamaskConnected, setMetamaskConnnected] = useState(false);
  const [account, setAccount] = useState();
  const [networkId, setNetworkId] = useState();
  const [isMetamask, setIsMetamask] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    await loadWeb3().then((data) => {
      if (data != false) {
        loadBlockchainData();
      }
    });
  });

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      // window.alert(
      //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
      // );
      setIsMetamask(false);
      return false;
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    const networkId = await web3.eth.net.getId();
    setNetworkId(networkId);

    if (accounts.length == 0) {
      setMetamaskConnnected(false);
    } else {
      setMetamaskConnnected(true);
      setAccount(accounts[0]);
    }

    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) setAccount(accounts[0]);
      else setAccount();
    });
    window.ethereum.on("networkChanged", (networkId) => {
      setNetworkId(networkId);
    });
  };

  return (
    <Provider store={store}>
      {networkId != 1 && metamaskConnected && (
        <div className="network-err-msg">
          <h3>Please switch to main network</h3>
        </div>
      )}
      {!isMetamask && (
        <Animated animationIn="BounceInLeft" animationOut="fadeOut" animationInDelay={500}>
          <div className="network-err-msg">
            <h3>Non-Ethereum browser detected. You should consider trying <a href="https://metamask.io/" target="_blank" rel="noreferrer">MetaMask!</a></h3>
          </div>
        </Animated>
      )}
      {
        loading ?
          <Loading />
          :
          <Router basename="/">
            <div>
              <ScrollUpButton
                EasingType="linear"
                ShowAtPosition={1000}
                AnimationDuration={501}
              />
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <Main
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
                {/*<Comingsoon />*/}
                {/* <Route
                  exact
                  path="/rewards"
                  element={
                    <Reflections
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                /> */}
                <Route
                  exact
                  path="/mint"
                  element={
                    <Mint
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
                 <Route
                  exact
                  path="/mintCross"
                  element={
                    <MintCross
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
                <Route
                  exact
                  path="/vault"
                  element={
                    <Vault
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
                <Route
                  exact
                  path="/claim"
                  element={
                    <Claim
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
                <Route
                  exact
                  path="/getVerified"
                  element={
                    <Signup
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
                <Route
                  exact
                  path="/policy"
                  element={
                    <Policy
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
                <Route
                  exact
                  path="/terms"
                  element={
                    <Terms
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
{/*                 <Route
                  exact
                  path="/auction"
                  element={
                    <Auction
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                />
                <Route
                  exact
                  path="/auction/:id"
                  element={
                    <AuctionSub
                      metamaskConnected={metamaskConnected}
                      setMetamaskConnnected={setMetamaskConnnected}
                      account={account}
                    />
                  }
                /> */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </Router>
      }
    </Provider>
  );
};

export default App;
